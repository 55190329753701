import { useSelector } from "react-redux";
import CustomSider from "@/components/common/CustomSider/index.jsx";
import ChatPluginContent from "./components/PluginContent/index.jsx";
import ChatPluginHistory from "./components/PluginHistory/index.jsx";
import ChatPluginAction from "./components/PluginAction/index.jsx";
import styles from "./index.module.less";

function ChatSider({ tutors, prompts, showSider, setShowSider, setText, messageList, setMessageList, curSessionId }) {
  const { quickReply } = useSelector((state) => state.chat);
  const quickReplyValue = quickReply || "普通对话";
  return (
    <CustomSider
      show={showSider}
      toggle={setShowSider}
      width={"400px"}
    >
      <div className={styles.chatSider}>
        <ChatPluginContent
          tutors={tutors}
          prompts={prompts}
          quickReply={quickReplyValue}
        />
        <ChatPluginHistory
          quickReply={quickReplyValue}
          curSessionId={curSessionId}
        />
        <ChatPluginAction
          quickReply={quickReplyValue}
          tutors={tutors}
          setText={setText}
          messageList={messageList}
          setMessageList={setMessageList}
        />
      </div>
    </CustomSider>
  );
}

export default ChatSider;
