import React, { useEffect, useState, Fragment, useRef, forwardRef, useImperativeHandle, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./index.module.less";
import Scrollbars from "react-custom-scrollbars-2";
import classNames from "classnames";
import cls from "clsx";
import { Avatar, Tooltip } from "antd";
import MessageContentRender from "@/components/common/MessageContentRender";
import { getModelName } from "@/constants/model";
import ScrollToBottom from "@/components/common/ScrollToBottom";
import ScrollToTop from "@/components/common/ScrollToTop";
import useScreenSize from "@/hooks/useScreenSize";
import { throttle } from "lodash-es";
import ThingIcons from "@/icons/ThingIcons";

const ChatBody = forwardRef((props, ref) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const scrollTopRef = useRef(null);
  const scrollBotRef = useRef(null);
  const { middle } = useScreenSize();
  const { pathname } = useLocation();
  let { messageList, setText, onOutlineSend, setMessageList, onRefineSend } = props;
  const chatBodyScroll = useRef(null);
  const { pluginPlaceHolder, inputHeight, quickReply } = useSelector((state) => state.chat);
  const { showPreview } = useSelector((state) => state.history);
  // 其他类型message
  const systemType = ["message-loading", "plugin-placeholder"];

  // 滚动到底部
  useEffect(() => {
    setTimeout(() => {
      if (chatBodyScroll.current) chatBodyScroll.current?.scrollToBottom("smooth");
    }, 1000);
  }, [messageList]);

  // 流式输出的过程中，自动滚动到底部
  const onMessageTyping = throttle(() => {
    if (chatBodyScroll.current) chatBodyScroll.current?.scrollToBottom("smooth");
  }, 1000);

  const onScrollFrame = ({ clientHeight, scrollTop, scrollHeight }) => {
    if (scrollTopRef?.current?.style) {
      scrollTopRef.current.style.visibility = scrollTop > 0 ? "visible" : "hidden";
    }
    if (scrollBotRef?.current?.style) {
      scrollBotRef.current.style.visibility = clientHeight + scrollTop < scrollHeight - 1 ? "visible" : "hidden";
    }
  };

  useImperativeHandle(ref, () => ({}));

  const createNewSession = useCallback(() => {
    const cacheQuickReply = quickReply;
    const cachePlaceHolder = pluginPlaceHolder;
    setText("");
    if (quickReply) {
      dispatch.chat.resetSession();
      dispatch.chat.update({
        quickReply: cacheQuickReply,
        replyVersion: Date.now(),
        placeHolderText: cachePlaceHolder,
        chatAtHome: false,
        windowTitle: "新会话",
      });
    } else {
      dispatch.chat.resetSession();
      dispatch.chat.update({
        chatAtHome: false,
        windowTitle: "新会话",
      });
    }
    setMessageList([]);
    navigate("/chat", { replace: true });
  }, [quickReply, pluginPlaceHolder, setText, setMessageList, messageList]);

  return (
    <div className={classNames(styles.MessageContainer, "MessageContainer")}>
      <Scrollbars
        autoHide
        autoHideTimeout={1000}
        autoHideDuration={200}
        style={{ height: `calc(100% - ${inputHeight}px)` }} // 输入框高度和新对话按钮
        className="message-scrollbar"
        ref={chatBodyScroll}
        onScrollFrame={onScrollFrame}
      >
        <div className={classNames("messageList", pathname)}>
          {messageList.length > 0
            ? messageList.map((msg, index) => {
                return msg.content.message_type && systemType.includes(msg.content.message_type) ? (
                  <div
                    className={cls("systemMessage", {
                      "plugin-placeholder": pluginPlaceHolder,
                    })}
                    key={index}
                  >
                    <MessageContentRender
                      msg={msg}
                      setText={setText}
                      onMessageTyping={onMessageTyping}
                      onOutlineSend={onOutlineSend}
                      messageList={messageList}
                      setMessageList={setMessageList}
                      onRefineSend={onRefineSend}
                    />
                  </div>
                ) : (
                  // data-id用于删除功能 Message left right用于删除样式修改
                  <div
                    className={cls({
                      "messageLeftWrapper Message left": msg.position === "left",
                      "messageRightWrapper Message right": msg.position === "right",
                    })}
                    style={{ flexDirection: middle ? "row" : "column" }}
                    data-id={`${msg.content.messageId}-${msg.position === "left" ? "answer" : "question"}`}
                    key={index}
                  >
                    {msg.user.avatar && msg.position === "right" && (
                      <div
                        className="avatar"
                        style={{ margin: middle ? "0 10px" : "10px 0" }}
                      >
                        <Avatar
                          size={36}
                          src={msg.user.avatar}
                        ></Avatar>
                      </div>
                    )}
                    {msg.user.avatar && msg.position === "left" && (
                      <div
                        className="avatar"
                        style={{ margin: middle ? "0 10px" : "10px 0" }}
                      >
                        <Tooltip
                          title={getModelName(msg.content.model)}
                          placement="topLeft"
                          arrowPointAtCenter
                        >
                          <Avatar
                            size={36}
                            src={msg.user.avatar}
                          ></Avatar>
                        </Tooltip>
                      </div>
                    )}
                    <MessageContentRender
                      msg={msg}
                      setText={setText}
                      onMessageTyping={onMessageTyping}
                      onOutlineSend={onOutlineSend}
                      messageList={messageList}
                      setMessageList={setMessageList}
                      onRefineSend={onRefineSend}
                    />
                  </div>
                );
              })
            : null}
          {pathname === "/chat" && !showPreview && messageList.length > 0 && (
            <div className="createNewSession">
              <div
                className="newSessionBtn"
                onClick={createNewSession}
              >
                <ThingIcons type="newChat" />
                <span className="name">开启新会话</span>
              </div>
            </div>
          )}
        </div>
      </Scrollbars>

      {!showPreview ? <ScrollToTop ref={scrollTopRef} /> : null}
      {!showPreview ? <ScrollToBottom ref={scrollBotRef} /> : null}
    </div>
  );
});

ChatBody.displayName = "ChatBody";
export default React.memo(ChatBody);
