export const history = {
  state: {
    active: false,
    showPreview: false, // 是否显示预览
    previewItem: null, // 预览item信息
    cacheList: [], // 缓存列表
  }, // initial state
  reducers: {
    // handle state changes with pure functions
    update(state, payload) {
      return {
        ...state,
        ...payload,
      };
    },
    toggleActive(state, payload) {
      return {
        ...payload,
        showPreview: false,
        previewItem: null,
        cacheList: [],
      };
    },
    resetPreview(state, payload) {
      return {
        active: false,
        showPreview: false,
        previewItem: null,
        cacheList: [],
      };
    },
    closePreview(state, payload) {
      return {
        ...state,
        ...payload,
        showPreview: false,
        previewItem: null,
        cacheList: [],
      };
    },
  },
  effects: (dispatch) => ({}),
};
