import React, { useCallback, useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spin, Empty, Input } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import classNames from "classnames";
import { useRequest } from "ahooks";
import Scrollbars from "react-custom-scrollbars-2";
import emptyImg from "@/assets/empty.png";
import { data as dataService } from "@/services/data";
import { feedback } from "@/utils/index";
import { getWindowSize, getFormattedDate } from "@/utils/helper.js";
import useScreenSize from "@/hooks/useScreenSize";
import ActionIcons from "@/icons/ActionIcons";
import HistoryList from "./HistoryList/index.jsx";
import HistoryPreview from "./HistoryPreview/index.jsx";
import styles from "./index.module.less";

function HistoryOverlay({ withLeftMenu }) {
  const { height: screenHeight } = getWindowSize();
  const dispatch = useDispatch();
  const scrollbarRef = useRef(null);
  const [keyword, setKeyword] = useState("");
  const isInit = useRef(true);
  const currentScrollTop = useRef(0);
  const { tokenInfo } = useSelector((state) => state.user);
  const { version } = useSelector((state) => state.document);
  const { jwt } = useSelector((state) => state.token);
  const { showPreview } = useSelector((state) => state.history);
  const { xlarge, middle, small } = useScreenSize();
  const [offsetTop, setOffsetTop] = useState(0);
  const [requesting, setRequesting] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize] = useState(Math.max(10 * Math.ceil((Math.max(screenHeight, 1080) - 100 - 62) / 10 / 50), 20));
  const [total, setTotal] = useState(0);
  const [loadedList, setLoadedList] = useState([]);
  const [todayList, setTodayList] = useState([]);
  const [weekList, setWeekList] = useState([]);
  const [monthList, setMonthList] = useState([]);
  const [earlyList, setEarlyList] = useState([]);
  const [ordering, setOrdering] = useState(tokenInfo?.session_order);
  const [isBatchDel, setIsBatchDel] = useState(false);
  const [batchDeleteList, setBatchDeleteList] = useState([]);

  const onScrollFrame = ({ clientHeight, scrollTop, scrollHeight }) => {
    setOffsetTop(scrollTop);
    if (scrollTop > 0 && scrollTop !== currentScrollTop.current) {
      currentScrollTop.current = scrollTop;
    }
    if (clientHeight + Math.floor(scrollTop) > scrollHeight - 5 && !requesting) {
      setPage(page + 1);
    }
  };

  const onEscKeydownEvent = (event) => {
    if (event.key === "Escape") {
      if (showPreview) {
        dispatch.history.closePreview();
        return;
      }
      dispatch.history.toggleActive({ active: false });
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", onEscKeydownEvent);
    return () => {
      document.removeEventListener("keydown", onEscKeydownEvent);
    };
  }, [showPreview]);

  useEffect(() => {
    if (page === 1 && requesting) {
      return;
    }
    // 如果已经是第一页，则直接搜索
    if (page === 1 && !requesting && !isInit.current) {
      isInit.current = false;
      setRequesting(true);
      getSessionListByPage();
      return;
    }
    // 否则触发从第一页开始加载
    setPage(1);
  }, [jwt, version]);

  useEffect(() => {
    if (page === 1 && requesting) {
      return;
    }

    if ((page - 1) * pageSize <= total) {
      isInit.current = false;
      jwt && setRequesting(true);
      getSessionListByPage();
    } else {
      showMessage();
    }
  }, [page]);

  useEffect(() => {
    dispatch.history.closePreview({});
  }, [keyword]);

  useEffect(() => {
    reset();
    getSessionListByPage();
    setRequesting(true);
  }, [ordering]);

  const reset = () => {
    setTotal(0);
    setLoadedList([]);
    setTodayList([]);
    setWeekList([]);
    setMonthList([]);
    setEarlyList([]);
    page > 1 && setPage(1);
  };

  const searchHandler = useCallback(
    (value) => {
      setKeyword(value);
      reset();
      // 搜索
      getSessionListByPage();
      setRequesting(true);
    },
    [setKeyword, page]
  );

  const showMessage = () => {
    const ptarget = document.querySelector(".history-scrollbar");
    const element = ptarget?.querySelector("div.history-list-wrapper");
    element.classList.add("scrolling-bottom");
  };

  const onClose = useCallback(() => {
    dispatch.history.toggleActive({ active: false });
  }, []);

  const onDelete = useCallback(
    (item, type) => {
      const typeIndex = ["todayList", "weekList", "monthList", "earlyList"].indexOf(type);
      if (typeIndex < 0) {
        return;
      }
      const list = [todayList, weekList, monthList, earlyList][typeIndex];
      const itemIndex = list.findIndex((x) => x.id === item.id);
      if (itemIndex < 0) {
        return;
      }
      const newList = list.map((x, index) => ({ ...x, deletedStatus: x.deletedStatus || index === itemIndex }));
      if (typeIndex === 0) {
        setTodayList(newList);
        return;
      }
      if (typeIndex === 1) {
        setWeekList(newList);
        return;
      }
      if (typeIndex === 2) {
        setMonthList(newList);
        return;
      }
      if (typeIndex === 3) {
        setEarlyList(newList);
      }
    },
    [todayList, weekList, monthList, earlyList]
  );

  useEffect(() => {
    const tList = [];
    const wList = [];
    const mList = [];
    const eList = [];

    loadedList.forEach((item) => {
      const { type, value } = getFormattedDate(
        tokenInfo?.session_order.includes("modified") ? item.modified_at : item.created_at
      );
      const newItem = {
        ...item,
        modified_at_formatted: value,
      };
      switch (type) {
        case "today":
          tList.push(newItem);
          break;
        case "week":
          wList.push(newItem);
          break;
        case "month":
          mList.push(newItem);
          break;
        case "earlier":
          eList.push(newItem);
          break;
      }
    });
    tList.length > todayList.length ? setTodayList(tList) : null;
    wList.length > weekList.length ? setWeekList(wList) : null;
    mList.length > monthList.length ? setMonthList(mList) : null;
    eList.length > earlyList.length ? setEarlyList(eList) : null;
  }, [loadedList]);

  const { run: getSessionListByPage, loading } = useRequest(
    () =>
      dataService.getSessionByPage({
        search: keyword,
        page_size: pageSize,
        page,
        ordering,
      }),
    {
      manual: true,
      ready: !!jwt,
      cacheKey: "cacheKey-history",
      cacheTime: -1,
      refreshDeps: [],
      debounceWait: 500,
      onSuccess: (res) => {
        setRequesting(false);
        if (res.status === "OK") {
          setTotal(res.data?.count || 0);
          setLoadedList(page === 1 ? [...res.data.results] : [...loadedList, ...res.data.results]);
          if (scrollbarRef?.current) {
            setTimeout(() => {
              scrollbarRef.current.scrollTop(currentScrollTop.current || 0);
            });
          }
        }
      },
      onError: (err) => {
        setRequesting(false);
        feedback(err.data);
      },
      onFinally: () => {
        setRequesting(false);
      },
    }
  );

  const onBatchDelete = useCallback(() => {
    setBatchDeleteList([]);
    setIsBatchDel(false);
    reset();
    getSessionListByPage();
    setRequesting(true);
  });

  return (
    <div className={classNames(styles.historyOverlay, { [styles.withLeftMenu]: withLeftMenu })}>
      <div
        className={classNames({
          historyOverlayContent: small || !showPreview,
          historyOverlayContentWithPreview: middle && showPreview,
        })}
      >
        {middle ? (
          <div className="historyOverlayClose">
            <span onClick={onClose}>
              <CloseOutlined />
            </span>
          </div>
        ) : null}
        <div
          className={classNames({
            historyContainer: !showPreview,
            historyContainerWithPreview: showPreview,
          })}
        >
          <div
            className={classNames("historyHeader", {
              historyHeaderFixed: offsetTop >= 100 || showPreview,
            })}
          >
            历史会话
          </div>
          <div
            className={classNames("historySearchWrapper", {
              historySearchWrapperFixed: offsetTop >= 100 || showPreview,
            })}
          >
            <div className={classNames("historySearchInner", { historySearchWithClose: small })}>
              <div className={classNames("historySearch")}>
                <Input
                  addonBefore={<ActionIcons type="search" />}
                  placeholder="搜索历史会话，支持工具名称、标题、问题、答案"
                  allowClear
                  onChange={(e) => searchHandler(e.target.value)}
                />
              </div>
              {!middle ? (
                <div className="historySearchClose">
                  <span onClick={onClose}>
                    <CloseOutlined />
                  </span>
                </div>
              ) : null}
            </div>
          </div>
          <div
            className={classNames("historyPreviewWrapper", {
              historyPreviewWrapperWithPreview: middle && showPreview,
              historyPreviewWrapperFixed: offsetTop >= 100 || showPreview,
            })}
          >
            <div className="historyListWrapper">
              <Scrollbars
                ref={scrollbarRef}
                autoHide
                autoHideTimeout={1000}
                autoHideDuration={200}
                style={{ height: "calc(100% - 80px)" }}
                onScrollFrame={onScrollFrame}
                className="history-scrollbar"
              >
                {!requesting && !loadedList?.length ? (
                  <Empty
                    description={"暂无历史会话~"}
                    image={emptyImg}
                  />
                ) : null}
                {loadedList?.length ? (
                  <HistoryList
                    todayList={todayList}
                    weekList={weekList}
                    monthList={monthList}
                    earlyList={earlyList}
                    onDelete={onDelete}
                    setOrdering={setOrdering}
                    isBatchDel={isBatchDel}
                    setBatchDel={setIsBatchDel}
                    batchDeleteList={batchDeleteList}
                    updateBatchSelected={(selected) => {
                      setBatchDeleteList([...selected]);
                    }}
                    onBatchDelete={onBatchDelete}
                  />
                ) : null}
                {requesting ? (
                  <Spin
                    tip="加载中..."
                    style={{ display: "block", marginTop: "0px" }}
                  />
                ) : null}
              </Scrollbars>
            </div>
            {showPreview && middle && (
              <div className="historyPreviewContainer">
                <HistoryPreview />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(HistoryOverlay);
