import cls from "classnames";
import EditorInput from "../EditorInput";
import EditorLangSelector from "../EditorLangSelector";
import styles from "./index.module.less";
import { useEffect } from "react";

const EditorHeader = ({ title, error = [], lang, style, updateText }) => {
  useEffect(() => {
    if (!lang) {
      updateText({ text: "Chinese", type: "lang" });
    }
  }, []);
  const dataOptions = [
    {
      label: "中文",
      value: "Chinese",
    },
    {
      label: "英文",
      value: "English",
    },
    {
      label: "俄语",
      value: "Russian",
    },
    {
      label: "日语",
      value: "Japanese",
    },
    {
      label: "韩语",
      value: "Korean",
    },
    {
      label: "德语",
      value: "German",
    },
    {
      label: "法语",
      value: "French",
    },
    {
      label: "意大利语",
      value: "Italian",
    },
    {
      label: "西班牙语",
      value: "Spanish",
    },
    {
      label: "丹麦语",
      value: "Danish",
    },
    {
      label: "拉丁语",
      value: "Latin",
    },
    {
      label: "阿拉伯语",
      value: "Arabic",
    },
  ];
  return (
    <div className={cls(styles.editorHeader, "editorHeader")}>
      <EditorInput
        tagType="h3"
        label="标题："
        placeholder="请输入文章标题"
        value={title}
        required={true}
        invalid={error.includes("title")}
        onChange={({ text }) => updateText({ text, type: "title" })}
      />
      <EditorLangSelector
        label="语言："
        required={true}
        value={lang}
        placeholder="请选择语言"
        dataOptions={dataOptions}
        invalid={error.includes("lang")}
        onChange={(text) => updateText({ text, type: "lang" })}
        defaultValue={"Chinese"}
      />
      <EditorInput
        tagType="p"
        label="风格："
        placeholder="请输入文章整体写作风格"
        value={style}
        required={false}
        onChange={({ text }) => updateText({ text, type: "style" })}
      />
    </div>
  );
};

export default EditorHeader;
