import { useCallback, useEffect } from "react";
import classNames from "classnames";
import { cloneDeep } from "lodash-es";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CommentOutlined, FilePdfOutlined, HistoryOutlined } from "@ant-design/icons";
import styles from "./index.module.less";
import LeftMenuBottom from "./LeftMenuBottom";
import useScreenSize from "@/hooks/useScreenSize";

function LeftMenu() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { middle } = useScreenSize();
  const { active: historyActive, showPreview } = useSelector((state) => state.history);

  const navigateHandler = useCallback(
    async (to, active) => {
      // 如果点击已激活的选项且之前是激活历史记录tab的状态，则只退出历史记录即可
      dispatch.history.resetPreview();
      if (pathname !== to) {
        dispatch.user.update({ tokenLoading: true });
      }
      switch (pathname) {
        case "/chat":
          navigate(to, { replace: true, show: true });
          dispatch.chat.resetSession();
          break;
        case "/paper":
        case "/document":
          navigate(to, { replace: true, show: true });
          dispatch.document.resetSession();
          break;
        default:
          break;
      }
      dispatch.user.resetCheck();
    },
    [dispatch, pathname]
  );

  useEffect(() => {
    if (pathname === "/chat") {
      dispatch.document.resetSession();
    } else if (pathname === "/document" || pathname === "/paper") {
      dispatch.chat.resetSession();
    }
  }, [pathname]);

  const onToggleHistory = useCallback(() => {
    dispatch.history.toggleActive({ active: !historyActive });
  }, [historyActive]);

  return (
    <div
      className={styles.leftMenu}
      style={{ display: middle ? "block" : "none" }} // 大屏始终显示leftmenu 小屏幕隐藏
    >
      <div
        onClick={() => {
          navigateHandler("/chat");
          dispatch.chat.update({
            chatAtHome: true,
          });
        }}
        className="leftMenu-logo"
      >
        <img
          src="/logo512.png"
          alt="logo"
          style={{ borderRadius: "10px" }}
        />
      </div>
      <div className={classNames("leftMenu-main")}>
        <div
          onClick={() => {
            navigateHandler("/chat", historyActive);
            dispatch.chat.update({
              chatAtHome: true,
            });
          }}
          className={classNames("leftMenu-main-item", "tour-chat", { active: !historyActive && pathname === "/chat" })}
        >
          <CommentOutlined />
          <span>对话</span>
        </div>
        <div
          onClick={() => navigateHandler("/paper", historyActive)}
          className={classNames("leftMenu-main-item", "tour-paper", {
            active: !historyActive && (pathname === "/paper" || pathname === "/document"),
          })}
        >
          <FilePdfOutlined />
          <span>文献</span>
        </div>
        <div
          onClick={() => onToggleHistory()}
          className={classNames("leftMenu-main-item", "tour-history", { active: historyActive })}
        >
          <HistoryOutlined />
          <span>历史</span>
        </div>
      </div>
      <LeftMenuBottom />
    </div>
  );
}

export default LeftMenu;
