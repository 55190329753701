import EditorContent from "./EditorContent";
import { useState, useCallback, useLayoutEffect, forwardRef, useImperativeHandle } from "react";
import { last, cloneDeep, has } from "lodash-es";
import { useSelector, useDispatch } from "react-redux";
import { useRequest } from "ahooks";
import { feedback } from "@/utils/index";
import { data as dataService } from "@/services";
import { OUTLINE_EMPTY, formatOutline, parseOutline, validateOutline } from "./template";
import "./style.less";
import BubbleToolbar from "../BubbleToolbar";
import { Button } from "antd";
import ActionIcons from "@/icons/ActionIcons";
import imageUrl from "@/assets/bubbleBtnBg.png";
import cls from "classnames";

const OutlineBubble = forwardRef((props, ref) => {
  const {
    text,
    createdAt,
    finished,
    messageId,
    position,
    isLast,
    isContent,
    setComposerText,
    status,
    showButtons,
    messageList,
    onOutlineSend,
    setMessageList,
    config,
    totalLength,
    currentIndex,
    setCurrentIndex,
  } = props;
  const dispatch = useDispatch();
  const [outline, setOutline] = useState(OUTLINE_EMPTY);
  const [error, setError] = useState([]);
  const [dataOptions, setDataOptions] = useState([]);
  const [dataAllowed, setDataAllowed] = useState(false);
  const { paperId, paths } = useSelector((state) => state.document);
  const { showPreview } = useSelector((state) => state.history);
  const { checking } = useSelector((state) => state.user);

  useImperativeHandle(ref, () => {
    return {
      outline,
      validateResult,
    };
  });

  useLayoutEffect(() => {
    if (!text) {
      return;
    }
    parseCurrentOutline();
  }, []);

  useLayoutEffect(() => {
    if (!!paperId || last(paths)) {
      setDataAllowed(true);
      getPaperDataNames();
    }
  }, []);

  // 校验结果
  const validateResult = useCallback(() => {
    const validate = validateOutline(outline);
    let result = false;
    if (validate && validate.length) {
      setError([...validate]);
      result = true;
    }
    return result;
  });

  const { loading, run: parseCurrentOutline } = useRequest(
    () =>
      dataService.parseOutline({
        outline: text,
      }),
    {
      manual: true,
      ready: !!text,
      onSuccess: (res) => {
        if (!res || !res.data) {
          setOutline(OUTLINE_EMPTY);
          return;
        }
        setOutline(parseOutline(res.data));
      },
      onError: (err) => {
        feedback(err.data);
      },
    },
    { manual: true }
  );

  const { run: getPaperDataNames } = useRequest(
    () => dataService.paperDataNames(paperId || last(paths), {}),
    {
      manual: true,
      ready: !!paperId || last(paths),
      onSuccess: (res) => {
        if (!res || !res.data || !res.data.length) {
          setDataOptions([]);
          return;
        }
        setDataOptions(res.data.map((v, index) => ({ labe: v, value: v, key: `${index}-${v}` })));
      },
      onError: (err) => {
        feedback(err.data);
      },
    },
    { manual: true }
  );

  // 更新文本值或选项数据
  const updateText = useCallback(
    ({ type, text, level1Index, level2Index, level3Index }) => {
      const newOutline = cloneDeep(outline);
      if (["title", "lang", "style"].includes(type)) {
        newOutline[type] = text || "";
        setOutline(newOutline);
      }
      if (!level1Index && level1Index !== 0) {
        return;
      }
      newOutline.nodes = newOutline.nodes || [];
      // 只有level1
      if (!level2Index && level2Index !== 0) {
        newOutline.nodes[level1Index][type] = text;
        setOutline(newOutline);
        return;
      }
      // 只有level1、level2
      if ((!!level2Index || level2Index === 0) && !level3Index && level3Index !== 0) {
        newOutline.nodes[level1Index].nodes = newOutline.nodes[level1Index].nodes || [];
        newOutline.nodes[level1Index].nodes[level2Index][type] = text;
        setOutline(newOutline);
        return;
      }
      // 只有level1、level2、level3
      newOutline.nodes[level1Index].nodes[level2Index].nodes =
        newOutline.nodes[level1Index].nodes[level2Index].nodes || [];
      newOutline.nodes[level1Index].nodes[level2Index].nodes[level3Index][type] = text;
      setOutline(newOutline);
    },
    [outline, setOutline]
  );

  // 添加一级或二级或三级大纲
  const addNode = useCallback(
    ({ level1Index, level2Index, level3Index }) => {
      const newOutline = cloneDeep(outline);
      newOutline.nodes = newOutline.nodes || [];
      // 只有level1
      if (!level2Index && level2Index !== 0) {
        const nodes = [...newOutline.nodes];
        nodes.splice(level1Index + 1, 0, {
          nodeName: "",
          nodeDesc: "",
          nodeExpt: "",
          nodes: [],
        });
        newOutline.nodes = nodes;
        setOutline(newOutline);
        return;
      }
      // 只有level1、level2
      newOutline.nodes[level1Index].nodes = newOutline.nodes[level1Index].nodes || [];
      if ((!!level2Index || level2Index === 0) && !level3Index && level3Index !== 0) {
        const nodes = [...newOutline.nodes[level1Index].nodes];
        nodes.splice(level2Index + 1, 0, {
          nodeName: "",
          nodeDesc: "",
          nodeExpt: "",
          nodes: [],
        });
        newOutline.nodes[level1Index].nodes = nodes;
        setOutline(newOutline);
        return;
      }
      // 只有level1、level2、level3
      newOutline.nodes[level1Index].nodes[level2Index].nodes =
        newOutline.nodes[level1Index].nodes[level2Index].nodes || [];
      const nodes = [...newOutline.nodes[level1Index].nodes[level2Index].nodes];
      nodes.splice(level3Index + 1, 0, {
        nodeName: "",
        nodeDesc: "",
        nodeExpt: "",
      });
      newOutline.nodes[level1Index].nodes[level2Index].nodes = nodes;
      setOutline(newOutline);
    },
    [outline, setOutline]
  );

  // 删除一级或二级大纲
  const deleteNode = useCallback(
    ({ level1Index, level2Index, level3Index }) => {
      const newOutline = cloneDeep(outline);
      newOutline.nodes = newOutline.nodes || [];
      // 只有level1
      if (!level2Index && level2Index !== 0) {
        newOutline.nodes = newOutline.nodes.filter((ignore, index) => index !== level1Index);
        setOutline(newOutline);
        return;
      }
      // 只有level1、level2
      newOutline.nodes[level1Index].nodes = newOutline.nodes[level1Index].nodes || [];
      if ((!!level2Index || level2Index === 0) && !level3Index && level3Index !== 0) {
        newOutline.nodes[level1Index].nodes = newOutline.nodes[level1Index].nodes.filter(
          (ignore, index) => index !== level2Index
        );
        setOutline(newOutline);
        return;
      }
      // 只有level1、level2、level3
      newOutline.nodes[level1Index].nodes[level2Index].nodes =
        newOutline.nodes[level1Index].nodes[level2Index].nodes || [];
      newOutline.nodes[level1Index].nodes[level2Index].nodes = newOutline.nodes[level1Index].nodes[
        level2Index
      ].nodes.filter((ignore, index) => index !== level3Index);
      setOutline(newOutline);
    },
    [outline, setOutline]
  );

  const onOk = useCallback(() => {
    const validate = validateOutline(outline);
    if (validate && validate.length) {
      setError([...validate]);
      return;
    }
    setError([]);
    let arr = messageList.filter((item) => item._id !== "edit-outline");
    setMessageList(arr);
    onOutlineSend(formatOutline(outline));
  }, [outline, setOutline]);

  return (
    <div className={cls("outline-bubble", { disabled: showPreview })}>
      <EditorContent
        error={error}
        outline={outline}
        dataAllowed={dataAllowed}
        dataOptions={dataOptions}
        updateText={updateText}
        addNode={addNode}
        deleteNode={deleteNode}
      />
      {!checking && finished && !isContent && !showButtons && (
        <BubbleToolbar
          text={text}
          createdAt={createdAt}
          setComposerText={setComposerText}
          messageId={messageId}
          position={position}
          isLast={isLast}
          isContent={isContent}
          status={status}
          totalLength={totalLength}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
          allowReAnswer={false}
        />
      )}
      {showButtons && (
        <div className="outline-buttons">
          <Button
            type="primary"
            onClick={onOk}
            icon={<ActionIcons type="edit" />}
            className="outline-button"
            style={{
              backgroundImage: `url(${imageUrl})`,
              backgroundSize: "100% 100%",
              backgroundRepeat: "no-repeat",
            }}
          >
            根据大纲撰写
          </Button>
        </div>
      )}
    </div>
  );
});

OutlineBubble.displayName = "OutlineBubble";
export default OutlineBubble;
