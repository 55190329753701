import React, { useState } from "react";
import styles from "./index.module.less";
import HistoryItem from "../HistoryItem/index.jsx";
import classNames from "classnames";

function HistorySection({ title, dataset = [], onDelete, isBatchDel, updateBatchSelected, batchDeleteList }) {
  const [focusId, setFocusId] = useState(null);
  return (
    <div className={classNames(styles.historySection, "history-section")}>
      <h3>{title}</h3>
      {dataset.map((item) => {
        return (
          <HistoryItem
            detail={item}
            onDelete={onDelete}
            key={item.id}
            isFocusItem={focusId === item.id}
            setFocusItem={(id) => setFocusId(id)}
            isBatchDel={isBatchDel}
            batchDeleteList={batchDeleteList}
            updateBatchSelected={updateBatchSelected}
          />
        );
      })}
    </div>
  );
}

export default React.memo(HistorySection);
