import React, { useEffect, useState } from "react";
import PluginCardIcons from "@/icons/PluginCardIcons/index";
import styles from "./index.module.less";
import FloatSiderIcons from "@/icons/FloatSiderIcons";
import cls from "classnames";

const ChatPluginContent = ({ quickReply, prompts, tutors }) => {
  const [descList, setDescList] = useState([]);
  const ignoreList = [
    "知网检索",
    "PubMed检索",
    "arXiv检索",
    "信息提取",
    "生成PPT",
    "AI视频",
    "数据分析",
    "文本数据分析",
    "生成大纲",
    "文章读写",
    "图生论文",
    "循证问答",
    "普通对话",
  ];

  useEffect(() => {
    if (!quickReply || !prompts || !prompts[quickReply]) {
      setDescList([]);
      return;
    }
    setDescList(prompts[quickReply].split("\n") || []);
  }, [prompts, quickReply]);

  const onClickTutor = () => {
    const url = tutors[quickReply];
    if (url) {
      window.open(url, "blank");
    } else {
      window.open("https://www.yuque.com/bi_wk/muac4q/mruw6a3ngmr1slo5", "blank");
    }
  };

  return (
    <div className={styles.ChatPluginContent}>
      <div className="pluginIcon">
        <span
          className={cls({
            fillColor: !ignoreList.includes(quickReply),
          })}
        >
          <PluginCardIcons type={quickReply} />
        </span>
      </div>
      <div className="pluginName">
        <span>{quickReply}</span>
      </div>
      {!descList?.length ? null : (
        <ul className="pluginDesc">
          {descList.map((item, idx) => {
            return (
              <li
                className="pluginDescItem"
                key={idx}
              >
                <span className="pluginDescItemDot"></span>
                <span className="pluginDescItemText">{item}</span>
              </li>
            );
          })}
          <li className="pluginDescItem">
            <span className="pluginDescItemDot"></span>
            <span className="pluginDescItemText">
              详细
              <span
                className="pluginDescLink"
                onClick={onClickTutor}
              >
                使用教程
                <FloatSiderIcons type="doubleArrowRight" />
              </span>
            </span>
          </li>
        </ul>
      )}
    </div>
  );
};

export default React.memo(ChatPluginContent);
