import * as CustomIcon from "./svg-icons";

const ReplyOptions = {
  翻译全文: "fanyi",
  润色全文: "runse",
  参考文献: "wenxian",
  提取大纲: "dagang",
  思维导图: "mindmap",
  信息提取: "tiqv",
  转成MD: "md",
  论文撰写: "zhuanxie",
  文献综述: "zongshu",
  开题报告: "baogao",
  文章重组: "chongzu",
  逐一问答: "wenda",
  普通问答: "wenda",
  数据撰写: "datazhuanxie",
  数据探索: "datatansuo",
  数据分析: "datafenxi",
};

export default function QuickRepliesIcons({ type }) {
  const typeEn = ReplyOptions[type];
  if (!typeEn) {
    return CustomIcon["wenda"];
  }

  return CustomIcon[typeEn];
}
