export const document = {
  state: {
    sessionId: undefined,
    lastMessageId: undefined,
    folderId: undefined,
    version: Date.now(),
    windowTitle: undefined,
    paperInfo: {},
    file: undefined, //check if pdfviewer is open
    paths: [],
    sessionType: 1,
    documentAtHome: true,
    paperId: undefined,
    paperTitle: undefined,
    paperNavPaths: [],
    currentChatType: 1, // 1: 单篇 2: 文件夹
    parent: undefined, //记录文献的父id 上传文献
    subPaper: false, //是否是子文献
    folderName: undefined,
  },
  reducers: {
    update(state, payload) {
      return {
        ...state,
        ...payload,
      };
    },
    // eslint-disable-next-line no-unused-vars
    resetSession(state, payload) {
      return {
        ...state,
        sessionId: undefined,
        lastMessageId: undefined,
        folderId: undefined,
        version: Date.now(),
        windowTitle: undefined,
        paperInfo: {},
        file: undefined,
        paths: [],
        sessionType: 1,
        documentAtHome: true,
        paperTitle: undefined,
        paperNavPaths: [],
        paperId: undefined,
        subPaper: false,
        parent: undefined,
        folderName: undefined,
        currentChatType: 1,
      };
    },
    // eslint-disable-next-line no-unused-vars
    version(state, payload) {
      return { ...state, version: Date.now() };
    },
    uploadLastMessageId(state, payload) {
      return { ...state, lastMessageId: payload };
    },
  },
  // eslint-disable-next-line no-unused-vars
  effects: (dispatch) => ({}),
};
