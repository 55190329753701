import styles from "./index.module.less";
import { Collapse } from "antd";
import ToolbarIcons from "@/icons/ToolbarIcons";

const { Panel } = Collapse;

function AdditionalMessage({ config = null, processingText = [], messageReText = "", text }) {
  const { reasoning_content = null, processing = [], show_diff = false } = config || {};

  return (
    <>
      {reasoning_content || messageReText || processingText.length > 0 || processing.length > 0 ? (
        <div
          className={styles.AdditionalMessage}
          style={{ borderRadius: show_diff ? "8px 8px 0 0" : "8px" }}
        >
          {(processing.length > 0 || processingText.length > 0) && (
            <div className="processing">
              {(processing.length > 0 ? processing : processingText).map((item, index) => {
                return (
                  <div
                    key={index}
                    className="content-item"
                  >
                    <ToolbarIcons type={item.status} />
                    {item.content}
                    {!text &&
                      !messageReText &&
                      !reasoning_content &&
                      (index === processing.length - 1 || index === processingText.length - 1) && (
                        <span className="typing-cursor"></span>
                      )}
                  </div>
                );
              })}
            </div>
          )}
          {(reasoning_content || messageReText) && (
            <Collapse
              bordered={false}
              expandIconPosition={"end"}
              defaultActiveKey={["1"]}
            >
              <Panel
                header={
                  <div className="header">
                    <ToolbarIcons type="deepIcon" />
                    <span>深度思考过程</span>
                  </div>
                }
                key="1"
              >
                <p className="content">
                  {reasoning_content || messageReText}
                  {text ? "" : <span className="typing-cursor"></span>}
                </p>
              </Panel>
            </Collapse>
          )}
        </div>
      ) : null}
    </>
  );
}

export default AdditionalMessage;
