import { useState, useRef, useEffect } from "react";
import { Menu, Button, Dropdown } from "antd";
import { RightOutlined, LeftOutlined, DownOutlined } from "@ant-design/icons";
import styles from "./index.module.less";
import QuickRepliesIcons from "@/icons/QuickRepliesIcons";
import cls from "classnames";

function ScrollableTabsWithArrows({ items, activeKey, onChange }) {
  const menuRef = useRef(null);
  const containerRef = useRef(null);
  const [scrollState, setScrollState] = useState({
    canScrollLeft: false,
    canScrollRight: false,
  });
  const [hiddenItems, setHiddenItems] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  // 菜单滚动距离
  const scrollMenu = (direction) => {
    if (menuRef.current) {
      const scrollAmount = direction === "left" ? -200 : 200;
      menuRef.current.scrollBy({ left: scrollAmount, behavior: "smooth" });
      setTimeout(checkScrollState, 300);
    }
  };

  // 对比可视区域内显示的菜单项，通过data-key查找隐藏
  const checkScrollState = () => {
    if (!menuRef.current) return;

    const { scrollLeft, scrollWidth, clientWidth } = menuRef.current;
    const canScrollLeft = scrollLeft > 0;
    const canScrollRight = scrollLeft < scrollWidth - clientWidth;

    setScrollState({ canScrollLeft, canScrollRight });

    if (containerRef.current) {
      const menuItems = menuRef.current.querySelectorAll(".menu-item");
      const containerRect = containerRef.current.getBoundingClientRect();
      const hidden = [];

      menuItems.forEach((item) => {
        const itemRect = item.getBoundingClientRect();
        if (itemRect.right > containerRect.right || itemRect.left < containerRect.left) {
          const id = item.getAttribute("data-key");
          hidden.push(items.find((i) => i.id === id));
        }
      });
      setHiddenItems(hidden);
    }
  };

  useEffect(() => {
    checkScrollState();
    const resizeObserver = new ResizeObserver(checkScrollState);
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }
    return () => resizeObserver.disconnect();
  }, [items]);

  return (
    <div className={styles.ScrollableTabsWithArrows}>
      <Button
        className={cls("scroll-button left", {
          disabled: !scrollState.canScrollLeft,
        })}
        type="link"
        icon={<LeftOutlined />}
        onClick={() => scrollMenu("left")}
        disabled={!scrollState.canScrollLeft}
      />
      <div
        className="menu-container"
        ref={containerRef}
      >
        <div
          className="menu-scroll-wrapper"
          ref={menuRef}
        >
          <div className="menu-items">
            {items.map((item) => (
              <div
                key={item.id}
                data-key={item.id}
                className={cls("menu-item", {
                  active: activeKey === item.name,
                })}
                onClick={() => onChange(item)}
              >
                <div className="icon-bg">
                  <QuickRepliesIcons type={item.name} />
                </div>
                {item.name}
              </div>
            ))}
          </div>
        </div>
      </div>
      <Button
        className={cls("scroll-button right", {
          disabled: !scrollState.canScrollRight,
        })}
        type="link"
        icon={<RightOutlined />}
        onClick={() => scrollMenu("right")}
        disabled={!scrollState.canScrollRight}
      />
      {/* {hiddenItems.length > 0 && (
        <Dropdown
          menu={{ items: hiddenItems }}
          dropdownRender={(menu) => <div>test</div>}
          open={dropdownOpen}
          onOpenChange={(visible) => setDropdownOpen(visible)}
          placement="top"
        >
          <Button
            className={cls("scroll-button right", {
              disabled: !scrollState.canScrollRight,
            })}
            type="link"
            icon={<RightOutlined />}
            onClick={() => scrollMenu("right")}
            disabled={!scrollState.canScrollRight}
          />
        </Dropdown>
      )} */}
    </div>
  );
}

export default ScrollableTabsWithArrows;
