import React, { useState, useEffect, useLayoutEffect, useRef, useCallback } from "react";
import useScreenSize from "@/hooks/useScreenSize";
import { Radio, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import styles from "./index.module.less";
import SheetConfigModal from "@/components/common/GptComposer/SheetConfigEditor/SheetConfigModal";
import QuickRepliesIcons from "@/icons/QuickRepliesIcons";
import cls from "classnames";
import ScrollableTabsWithArrows from "./ScrollableTabsWithArrows";

const QuickReplies = (props) => {
  const { items = [], onClick, setText, messageList } = props;
  const dispatch = useDispatch();
  const [checkedQuickReplyMode, setCheckedQuickReplyMode] = useState("");
  const [isCheckable, setIsCheckable] = useState(false);
  const { middle } = useScreenSize();
  const { paperInfo } = useSelector((state) => state.document);
  const { quickReplyMode } = useSelector((state) => state.chat);
  const [open, setOpen] = useState(false); // 是否打开配置表单

  useEffect(() => {
    if (!items || !items.length) {
      setIsCheckable(false);
      return;
    }
    setIsCheckable(items.some((x) => x.checkable));
  }, [items]);

  useEffect(() => {
    if (!items || !items.length) {
      setCheckedQuickReplyMode("");
      return;
    }
    // 不支持选中的时候
    if (!items.some((x) => x.checkable)) {
      return;
    }
    // 默认选中列表第一个
    let name = items[0].name;
    // 如果有上个选中值，且上个选中值是列表之一（排除更新类型后，选中上个列表之一的情形），则还原之前的选中
    if (checkedQuickReplyMode && items.map((x) => x.name).includes(checkedQuickReplyMode)) {
      name = checkedQuickReplyMode;
    }
    setCheckedQuickReplyMode(name);
  }, [items, isCheckable, messageList]);

  useEffect(() => {
    setCheckedQuickReplyMode(quickReplyMode);
  }, [quickReplyMode]);

  useEffect(() => {
    // 划线翻译使用quickReplyMode记录，不清空
    if (quickReplyMode === "翻译") {
      return;
    }
    // 不支持选中的场景，清空模式
    if (!isCheckable) {
      dispatch.chat.update({
        quickReplyMode: null,
      });
      return;
    }
    // 支持选中的场景，切换模式，不直接发送
    dispatch.chat.update({
      quickReplyMode: checkedQuickReplyMode,
    });
  }, [isCheckable, checkedQuickReplyMode, setCheckedQuickReplyMode, dispatch]);

  const onQuickReplyClick = useCallback(
    (item, index) => {
      // 不支持选中的场景
      if (!isCheckable) {
        if (item.name === "翻译全文" || item.name === "润色全文") {
          const el = document.querySelector(".Composer-input");
          el.focus();
          let translateText = paperInfo.language === "zh" ? "翻译全文, 目标语言: 英文" : `翻译全文, 目标语言: 中文`;
          setText(item.name === "润色全文" ? "润色全文" : translateText);
          dispatch.chat.update({
            quickReplyMode: item.name,
          });
        } else if (item.name === "信息提取") {
          setOpen(true);
          dispatch.chat.update({
            quickReplyMode: item.name,
          });
        } else {
          onClick("text", item.name);
        }
        return;
      }
      // 支持选中的场景，切换模式，不直接发送
      setCheckedQuickReplyMode(item.name);
    },
    [isCheckable, checkedQuickReplyMode, items, onClick]
  );

  if (!items.length) return null;

  return (
    <div className={styles.QuickRepliesModes}>
      <div
        className="QuickReplies-Modes-inner"
        style={{ visibility: items?.length > 0 ? "visible" : "hidden" }}
      >
        {!(messageList.length === 1 && messageList[0]?.id === "empty") ? (
          <>
            {middle ? <span className="QuickReplies-Modes-title">{isCheckable ? "提问模式:" : "快捷问答:"} </span> : ""}
            <div className="QuickReplies-Modes-list">
              <ScrollableTabsWithArrows
                items={items}
                activeKey={checkedQuickReplyMode}
                onChange={(item) => {
                  onQuickReplyClick(
                    items.find((x) => x.name === item.name),
                    items.findIndex((x) => x.name === item.name)
                  );
                }}
              />
            </div>
          </>
        ) : null}
      </div>
      <SheetConfigModal
        open={open}
        setOpen={setOpen}
        setConfigName={null}
        onClick={onClick}
        type={"document"}
      />
    </div>
  );
};

export default React.memo(QuickReplies);
