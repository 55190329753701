import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { message, Input, Tooltip } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useRequest } from "ahooks";
import { last } from "lodash-es";
import useScreenSize from "@/hooks/useScreenSize";
import { data as dataService } from "@/services";
import { feedback } from "@/utils/index";
import ClearButton from "./ClearButton";
import styles from "./index.module.less";
import ToolbarIcons from "@/icons/ToolbarIcons";

const { Search } = Input;

const titles = {
  "/chat": "新会话",
  "/document": "",
};

function EditableTitle() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { windowTitle: chatTitle, sessionId: chatId, quickReply, chatAtHome } = useSelector((state) => state.chat);
  const {
    windowTitle: documentTitle,
    sessionId: documentId,
    paths,
    paperInfo,
    paperNavPaths,
  } = useSelector((state) => state.document);
  const chatTitlePrefix = chatId ? quickReply || "普通对话" : null;

  const [titleEditing, setTitleEditing] = useState(false);
  const [value, setValue] = useState(titles[pathname]);
  const [editName, setEditName] = useState("");
  const [sessionId, setSessionId] = useState();

  useEffect(() => {
    switch (pathname) {
      case "/chat":
        setValue(chatTitle || titles[pathname]);
        setSessionId(chatId);
        break;
      case "/document":
        setValue(documentTitle || titles[pathname]);
        setSessionId(documentId || last(paths));
        break;
      default:
        break;
    }
    setTitleEditing(false);
  }, [pathname, chatId, documentId, paths, chatTitle, documentTitle]);

  const titleChangeHandler = useCallback(
    (e) => {
      setValue(e.target.value);
    },
    [setValue]
  );

  const editorHandler = useCallback(() => {
    setTitleEditing(!!sessionId);
  }, [sessionId]);

  //失焦时取消修改并返回原样
  const blurHandler = useCallback(
    (e) => {
      let windowTitle;
      switch (pathname) {
        case "/chat":
          windowTitle = chatTitle;
          break;
        case "/document":
          windowTitle = documentTitle;
          break;
        default:
          break;
      }
      setTitleEditing(false);
      setValue(windowTitle);
    },
    [chatTitle, documentTitle, titleEditing, pathname]
  );

  const goBackHandler = useCallback(
    async (to) => {
      switch (to) {
        case "/chat":
          navigate(to, { replace: true, show: true });
          dispatch.chat.resetSession({ windowTitle: "新会话" });
          break;
        case "/paper":
          navigate(to, { replace: true, show: true });
          if (paths.length <= 0) {
            dispatch.document.resetSession();
            return;
          }
          dispatch.document.update({
            sessionId: undefined,
            paperInfo: null,
            file: null,
            sessionType: 2,
            paths: [...paths],
            paperNavPaths: paperNavPaths,
          });
          break;
        default:
          break;
      }
      dispatch.user.resetCheck();
    },
    [dispatch, pathname, paperInfo]
  );

  const { run: saveHandler } = useRequest(
    () =>
      dataService.updateSession(sessionId, {
        title: value,
      }),
    {
      ready: !!value,
      manual: true,
      onSuccess: (res) => {
        message.success("标题保存成功！");
        dispatch[pathname.replace("/", "")].update({
          windowTitle: res.data.title,
          version: Date.now(),
        });
      },
      onError: (err) => {
        feedback(err.data);
      },
      onFinally: () => {
        setTitleEditing(false);
      },
    }
  );

  return (
    <div className={styles.windowTitle}>
      <div className="title-container">
        {titleEditing ? (
          <div
            className="title-edit"
            onClick={(e) => e.stopPropagation()}
          >
            <Input
              value={value}
              onChange={titleChangeHandler}
              onKeyDown={(e) => {
                if (e && e.keyCode && e.keyCode === 13) {
                  saveHandler(e);
                }
              }}
            />

            <div className="title-action">
              <span
                onClick={() => {
                  setTitleEditing(false);
                  setValue(editName);
                  setEditName("");
                }}
              >
                <ToolbarIcons type="cancel" />
              </span>
              <span onClick={saveHandler}>
                <ToolbarIcons type="confirm" />
              </span>
            </div>
          </div>
        ) : (
          <div
            className="title-text"
            onClick={() => {
              editorHandler();
              setEditName(value);
            }}
          >
            {pathname === "/chat" && !chatAtHome && (
              <Tooltip title={"返回"}>
                <ArrowLeftOutlined
                  className="title-back-icon"
                  onClick={(e) => {
                    e.stopPropagation();
                    goBackHandler("/chat");
                  }}
                />
              </Tooltip>
            )}
            {pathname === "/document" && documentTitle && (
              <Tooltip title={"返回文献列表"}>
                <ArrowLeftOutlined
                  className="title-back-icon"
                  onClick={(e) => {
                    e.stopPropagation();
                    dispatch.document.update({
                      folderName: undefined,
                      subPaper: false,
                    });
                    goBackHandler("/paper");
                  }}
                />
              </Tooltip>
            )}
            <span>
              {chatTitlePrefix || ""}
              {chatTitlePrefix ? " / " : ""}
              {pathname === "/chat" && !chatAtHome && value}
              {pathname === "/document" && value}
            </span>
          </div>
        )}
        {(pathname === "/chat" || pathname === "/document") && (
          <ClearButton
            sessionId={sessionId}
            pathname={pathname}
          />
        )}
      </div>
    </div>
  );
}

export default EditableTitle;
