import { useCallback, useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import cls from "classnames";
import { Avatar, message, Popover, Badge } from "antd";
import { useTimeout } from "ahooks";
import useScreenSize from "@/hooks/useScreenSize";
import { MODEL_ICONS, MODEL_TIPS, MODEL_LIST, MODEL_FEATURES_LIST } from "@/constants/model";
import { TagTwoTone, CaretDownOutlined } from "@ant-design/icons";
import styles from "./index.module.less";

function ModelSegmented() {
  const dispatch = useDispatch();
  const ref = useRef(null);
  const { pathname } = useLocation();
  const { middle } = useScreenSize();
  const { model } = useSelector((state) => state.token);
  const modelSelected = MODEL_LIST.find((x) => x.value === model) ?? { label: "DeepSeek", value: "DEEPSEEK" };
  const [modelHovered, setModelHovered] = useState(modelSelected);

  const changeHandler = useCallback(
    (model) => {
      // 关闭popover-操作DOM，不优雅
      // const popContainer = ref.current.getContainer();
      // const popInstance = popContainer?.childNodes?.[0]?.childNodes?.[0];
      // if (popContainer && popInstance) {
      //   popContainer.style.display = "none";
      //   popInstance.style.left = "-1000px";
      //   popInstance.classList.add("ant-popover-hidden");
      //   setTimeout(() => {
      //     popContainer.style.display = "block";
      //   }, 200);
      // }
      // 关闭popover-调用底层非公开API
      ref.current?.setPopupVisible(false);

      // 如果是点击当前已选中的，则不做处理
      if (model === modelSelected.value) {
        return;
      }
      !middle &&
        message.success({
          content: MODEL_TIPS[model],
        });
      dispatch.token.update({ model });
    },
    [pathname, model]
  );

  return (
    <div
      className={cls(
        middle ? styles.tourModelContainer : styles.tourModelMobileContainer
        // middle && pathname !== "/chat" ? styles.tourModelContainerFull : "null"
      )}
    >
      <Popover
        ref={ref}
        title={null}
        overlayClassName={styles.tourModelPopoverOverlay}
        placement={middle ? "bottom" : "bottomLeft"}
        content={
          <div className={cls(styles.tourModelPopover)}>
            <div className="tour-model-popover-grids">
              {MODEL_LIST.map((_model, index) => {
                return (
                  <div
                    className={cls("tour-model-popover-item", {
                      "tour-model-popover-item-selected": _model.value === modelSelected.value,
                    })}
                    key={_model.value}
                    onClick={() => changeHandler(_model.value)}
                    onMouseEnter={() => setModelHovered(_model)}
                    onMouseLeave={() => setModelHovered(modelSelected)}
                  >
                    <Avatar
                      size={24}
                      src={MODEL_ICONS[_model.value]}
                    />
                    <div>{_model.label}</div>
                    {_model?.badge && (
                      <>
                        <div
                          className="badge"
                          style={{
                            backgroundColor: _model.badge.bgcolor,
                          }}
                        >
                          {_model.badge.label}
                        </div>
                      </>
                    )}
                  </div>
                );
              })}
            </div>
            <div className="tour-model-popover-header">
              <div className="tour-model-popover-tips">
                {MODEL_FEATURES_LIST[modelHovered.value]?.map((item, index) => {
                  return (
                    <div
                      key={`${index}-${modelHovered.value}`}
                      className="model-tips-item"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: 8,
                        fontSize: 13,
                      }}
                    >
                      <TagTwoTone />
                      <span>{item.description}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        }
      >
        <div
          className={cls(styles.tourModelSegmented, "tour-model-segmented")}
          id="tour-model-segmented"
        >
          <div className="tour-model-segmented-content">
            <Avatar
              size={20}
              src={MODEL_ICONS[modelSelected.value]}
            />
            <div>{modelSelected.label}</div>
            {middle ? null : <CaretDownOutlined />}
          </div>
        </div>
      </Popover>
    </div>
  );
}

export default ModelSegmented;
