export const MESSAGE_TYPES = {
  0: "text",
  1: "img",
  2: "arxiv-scholar",
  3: "google-scholar",
  4: "pubmed-scholar",
  5: "cnki-scholar",
  6: "wanfang-scholar",
  7: "wiley-scholar",
  8: "science-scholar",
  9: "suggestion-scholar",
  10: "github-QA",
  11: "fulltext-search",
  20: "music",
  30: "outline",
  31: "ppt",
  40: "video",
};

/* 备注answer_type
31 ppt
40 video
*/
