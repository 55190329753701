import { CSSTransition } from "react-transition-group";
import styles from "./index.module.less";
import FloatSiderIcons from "@/icons/FloatSiderIcons";
import classNames from "classnames";

function CustomSider({ children, show, toggle, width }) {
  return (
    <CSSTransition
      in={show}
      timeout={280}
    >
      <div
        className={classNames(styles.customSider)}
        style={{
          paddingLeft: !show ? "0" : "12px",
          paddingRight: !show ? "0" : "12px",
          transform: `translateX(${show ? 0 : "100%"})`,
          width: `${show ? width : "0px"}`,
        }}
      >
        {children}
        {show ? (
          <div className="custom-sider-toggle-close">
            <div
              className="custom-sider-toggle"
              onClick={() => {
                toggle(!show);
              }}
            >
              <FloatSiderIcons type="doubleArrowRight" />
            </div>
          </div>
        ) : (
          <div className="custom-sider-toggle-open">
            <div
              className="custom-sider-toggle"
              onClick={() => {
                toggle(!show);
              }}
            >
              <FloatSiderIcons type="doubleArrow" />
            </div>
          </div>
        )}
      </div>
    </CSSTransition>
  );
}

export default CustomSider;
