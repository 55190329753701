import styles from "./index.module.less";
import { Collapse } from "antd";
import { Fragment, useEffect, useRef, useState } from "react";
import * as Diff from "diff";
import ToolbarIcons from "@/icons/ToolbarIcons";

const { Panel } = Collapse;

function RefineDiffMessage({ config, answerId, answerText, questionText }) {
  const { show_diff = false, reasoning_content = null, processing = [] } = config || {};
  const diffRef = useRef(null);
  const [diffArr, setDiffArr] = useState([]);

  useEffect(() => {
    if (!show_diff) {
      setDiffArr([]);
      return;
    }
    setDiffArr([]);
    const diffArr = Diff.diffWords(questionText, answerText);
    setDiffArr(diffArr);
  }, [show_diff]);

  return (
    <Fragment>
      {show_diff && (
        <div
          className={styles.RefineDiffMessage}
          style={{ borderRadius: reasoning_content || processing.length > 0 ? "0 0 8px 8px" : "8px" }}
        >
          <Collapse
            bordered={false}
            expandIconPosition={"end"}
            defaultActiveKey={["1"]}
          >
            <Panel
              header={
                <div className="header">
                  <ToolbarIcons type="compareIcon" />
                  <span>对比效果</span>
                </div>
              }
              key="1"
            >
              <p
                ref={diffRef}
                className="diffText"
              >
                {diffArr.map((part, index) => (
                  <span
                    key={`${answerId}-${index}`}
                    className={part.added ? "diff-added" : part.removed ? "diff-removed" : "diff-unchanged"}
                  >
                    {part.value}
                  </span>
                ))}
              </p>
            </Panel>
          </Collapse>
        </div>
      )}
    </Fragment>
  );
}

export default RefineDiffMessage;
