import { isEmpty } from "lodash-es";
// chat相关配置
/*
  language: 语言
  dbSelect: 数据库选择
  knowledge: 文献库选择
  analysisType: 数据分析类型
  refine: 润色
  evidence: 循证问答
  online: 在线
  upload : 上传
  outline: 大纲编辑
  phrase: 常用语
  polish: 润色类型选择
  ratio: 分辨率选择
  voice: 声音选择
  paper: 文献list
*/
function getChatConfig(quickReply) {
  switch (quickReply) {
    case "论文降重":
      return ["refine"];
    case "论文润色":
      return ["polish"];
    case "翻译":
      return ["language"];
    case "生成大纲":
      return [];
    case "论文撰写":
    case "文献综述":
    case "开题报告":
      return ["dbSelect", "upload", "outline"];
    case "文章重组":
    case "文章优化":
      return ["upload", "outline"];
    case "学术问答":
      return ["dbSelect", "knowledge"];
    case "长文撰写":
      return ["outline"];
    case "文章读写":
      return ["upload"];
    case "图表数字化":
    case "代码重现":
    case "图生论文":
    case "AI视频":
      return ["upload"];
    case "绘制流程图":
    case "思维导图":
    case "语音转文字":
    case "OFFICE密码破解":
    case "批处理":
    case "生成PPT":
      return ["upload"];
    case "文字转语音":
      return ["voice"];
    case "图片解释":
    case "OCR图文识别":
    case "绘图编辑":
      return ["upload"];
    case "画图":
      return ["ratio", "upload"];
    case "数据分析":
      return ["upload", "analysisType"];
    case "循证问答":
      return ["evidence"];
    case "信息提取":
      return ["upload", "sheet"];
    case "普通对话":
    case "":
    case undefined:
    case null:
      return ["online", "upload", "phrase"];
    default:
      return [];
  }
}

export function chatConfig(quickReply) {
  return getChatConfig(quickReply);
}

// document相关配置

export function documentConfig(sessionType, quickReplyMode) {
  let config = [];
  if (sessionType === 2 && !["普通问答", "逐一问答"].includes(quickReplyMode)) {
    config.push("outline");
  }
  return config;
}
