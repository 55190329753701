import "katex/dist/katex.min.css";
import "@/themes/index.less";
import NormalMessage from "./NormalMessage";
import ResolveMessage from "./ResolveMessage";
import AnswersMessage from "./AnswersMessage";
import { MESSAGE_TYPES } from "@/constants/message";

function MessageContentRender({
  msg,
  setText,
  onMessageTyping,
  messageList,
  onOutlineSend,
  setMessageList,
  onRefineSend,
}) {
  let { content, position, question_type, type } = msg;
  if (type === "answers-message") {
    content.answers = content.answers.map((item) => {
      if (position === "left" && item?.message_type?.includes("scholar")) {
        try {
          JSON.parse(item.answer);
        } catch (error) {
          item = { ...item, message_type: "text" };
        }
      }
      return {
        ...item,
        message_type: MESSAGE_TYPES[item.answer_type],
      };
    });
  }
  switch (type) {
    case "normal-message":
      return (
        <NormalMessage
          key={content?.messageId}
          messageType={content.message_type}
          text={content?.text}
          createdAt={content?.createdAt}
          setComposerText={setText}
          messageId={content?.messageId}
          position={position}
          isLast={content?.isLast}
          questionType={content?.question_type}
          placeholderType={content?.placeholder_type}
          status={content?.status}
          isContent={content?.isContent}
          config={content?.config}
          onMessageTyping={onMessageTyping}
          showButtons={content?.showButtons}
          messageList={messageList}
          onOutlineSend={onOutlineSend}
          setMessageList={setMessageList}
          onRefineSend={onRefineSend}
          quickTypeList={content?.quickTypeList}
          showQuickType={content?.showQuickType}
        />
      );
    case "resolve-message":
      return (
        <ResolveMessage
          key={content?.messageId}
          setComposerText={setText}
          messageId={content?.messageId}
        />
      );
    case "answers-message":
      return (
        <AnswersMessage
          key={content?.messageId}
          content={content}
          setText={setText}
          messageList={messageList}
          setMessageList={setMessageList}
          onRefineSend={onRefineSend}
          onOutlineSend={onOutlineSend}
        />
      );
    default:
      return null;
  }
}

export default MessageContentRender;
