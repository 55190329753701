import React from "react";
import HistorySection from "./section.jsx";
import HistoryAction from "../HistoryAction/index.jsx";
import styles from "./index.module.less";
import classNames from "classnames";

function HistoryList({
  todayList,
  weekList,
  monthList,
  earlyList,
  onDelete,
  setOrdering,
  isBatchDel,
  setBatchDel,
  batchDeleteList,
  updateBatchSelected,
  onBatchDelete,
}) {
  return (
    <div className={styles.historyList}>
      <div className={classNames(styles.historyListWrapper, "history-list-wrapper")}>
        <HistoryAction
          setOrdering={setOrdering}
          isBatchDel={isBatchDel}
          setBatchDel={setBatchDel}
          batchDeleteList={batchDeleteList}
          updateBatchSelected={updateBatchSelected}
          onBatchDelete={onBatchDelete}
        />
        {todayList?.length ? (
          <HistorySection
            title="今天"
            dataset={todayList}
            onDelete={(item) => onDelete(item, "todayList")}
            isBatchDel={isBatchDel}
            batchDeleteList={batchDeleteList}
            updateBatchSelected={updateBatchSelected}
          />
        ) : null}
        {weekList?.length ? (
          <HistorySection
            title="7天"
            dataset={weekList}
            onDelete={(item) => onDelete(item, "weekList")}
            isBatchDel={isBatchDel}
            batchDeleteList={batchDeleteList}
            updateBatchSelected={updateBatchSelected}
          />
        ) : null}
        {monthList?.length ? (
          <HistorySection
            title="30天"
            dataset={monthList}
            onDelete={(item) => onDelete(item, "monthList")}
            isBatchDel={isBatchDel}
            batchDeleteList={batchDeleteList}
            updateBatchSelected={updateBatchSelected}
          />
        ) : null}
        {earlyList?.length ? (
          <HistorySection
            title="更早"
            dataset={earlyList}
            onDelete={(item) => onDelete(item, "earlyList")}
            isBatchDel={isBatchDel}
            batchDeleteList={batchDeleteList}
            updateBatchSelected={updateBatchSelected}
          />
        ) : null}
      </div>
    </div>
  );
}

export default React.memo(HistoryList);
