import { Fragment, useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Popover, Button, Badge, Modal } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useRequest } from "ahooks";
import { data as dataService } from "@/services";
import LeftBottomIcons from "@/icons/LeftBottomIcons";
import styles from "./index.module.less";
import { feedback } from "@/utils/index";

function MessageButton() {
  const { jwt } = useSelector((state) => state.token);
  const { refeashMessage } = useSelector((state) => state.user);
  const [total, setTotal] = useState(0); // 消息总数
  const [allMessages, setAllMessages] = useState([]); // 所有消息
  const [messageCount, setMessageCount] = useState(0); // 未读消息数
  const [openMessage, setOpenMessage] = useState(null);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { dark } = useSelector((state) => state.theme);

  const { run: getMessageList } = useRequest(() => dataService.getUserMessage(), {
    manual: true,
    ready: !!jwt,
    refreshDeps: [jwt],
    onSuccess: (res) => {
      if (res.data) {
        // 所有消息
        setTotal(res.data.count);
        setAllMessages(res.data.results);
        // 过滤未读消息
        let unCheckedList = res.data.results.filter((item) => item.checked === false);
        setMessageCount(unCheckedList.length);
        dispatch.user.update({ messageList: unCheckedList }); // 更新noticebar
        dispatch.user.update({ refeashMessage: false });

        // 消息弹出
        let message = res.data.results.find((item) => item.opening_in_window === true && item.checked === false);
        if (message) {
          setOpenMessage(message);
          setOpen(true);
        }
      }
    },
    onError: (err) => {
      feedback(err.data);
    },
  });

  useEffect(() => {
    getMessageList();
  }, []);

  useEffect(() => {
    refeashMessage && getMessageList();
  }, [refeashMessage]);

  const handleCancel = useCallback(
    (e) => {
      setOpen(false);
      const item = [openMessage.id];
      handlerBatchCheck(item);
    },
    [openMessage]
  );

  const { run: handlerBatchCheck } = useRequest((ids) => dataService.batchChecked({ ids }), {
    manual: true,
    onSuccess: (res) => {
      dispatch.user.update({ refeashMessage: true });
    },
    onError: (err) => {
      feedback(err.data);
    },
  });

  return (
    <Fragment>
      <Badge
        size="small"
        count={messageCount}
      >
        <Popover
          title={
            <div className="pop-title">
              <div>{`消息(${total})`}</div>
              <Button
                type="link"
                href="/console/message"
                style={{ padding: 0 }}
              >
                查看更多
              </Button>
            </div>
          }
          placement="right"
          getPopupContainer={(trigger) => trigger.parentNode}
          content={
            <div className="message-list">
              {allMessages.map((item) => {
                return (
                  <div
                    key={item.id}
                    className="messsage-item"
                    onClick={() => {
                      setOpenMessage(item);
                      setOpen(true);
                    }}
                    style={{
                      borderBottom: dark ? "1px solid rgba(128, 128, 128, 0.5)" : "1px solid rgba(166, 166, 166, 0.2)",
                    }}
                  >
                    <div className="message-info">
                      {item.checked ? (
                        <LeftBottomIcons type="envelope" />
                      ) : (
                        <Badge dot>
                          <LeftBottomIcons type="envelope" />
                        </Badge>
                      )}
                      <div className="message-title">{item.title}</div>
                    </div>
                    <div className="message-time">{item.created_at}</div>
                  </div>
                );
              })}
            </div>
          }
          overlayClassName={styles.messagesPopover}
        >
          <div className="leftMenuBottom-Btn message-button">
            {/* <BellOutlined style={{ cursor: "pointer", fontSize: 20 }} /> */}
            <LeftBottomIcons type="message" />
          </div>
        </Popover>
      </Badge>
      <Modal
        open={open}
        onCancel={() => {
          handleCancel();
        }}
        footer={null}
        mask={true}
        maskClosable={false}
        wrapClassName={styles.openMessageModal}
        width={600}
        closeIcon={<CloseOutlined style={{ color: "#A7B3BF" }} />}
        centered={true}
        title={openMessage?.title}
        style={{ top: 10 }}
      >
        <div className="message-container">
          <div className="content">{`${openMessage?.content}`}</div>
          {openMessage?.media && (
            <img
              src={openMessage?.media}
              alt=""
            />
          )}
          <div className="footer">
            <div className="time">
              <span>{openMessage?.created_at}</span>
            </div>
            <Button
              type="primary"
              size="small"
              className="know-button"
              onClick={() => {
                handleCancel();
              }}
            >
              我知道了
            </Button>
          </div>
        </div>
      </Modal>
    </Fragment>
  );
}

export default MessageButton;
