import WindowToolbar from "./WindowToolbar";
import ModelSegmented from "./ModelSegmented";
import EditableTitle from "./EditableTitle";
import useScreenSize from "@/hooks/useScreenSize";
import styles from "./index.module.less";
import classNames from "classnames";

function WindowHeader() {
  const { middle } = useScreenSize();
  return (
    <div className={styles.header}>
      <div className={classNames("head-right")}>
        {middle && <EditableTitle />}
        <ModelSegmented />
        <WindowToolbar />
      </div>
    </div>
  );
}

export default WindowHeader;
