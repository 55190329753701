import { useState } from "react";
import { useRequest } from "ahooks";
import { useSelector, useDispatch } from "react-redux";
import QuickRepliesIcons from "@/icons/QuickRepliesIcons";
import { Dropdown, Divider } from "antd";
import { data as dataService } from "@/services/data";
import cls from "classnames";
import FileColorIcons from "@/icons/FileColorIcons";
import DirectionActions from "@/icons/DirectionActions";
import styles from "./index.module.less";

function EmptyBubble({ quickTypeList = [], showQuickType }) {
  const dispatch = useDispatch();
  const { quickReplyMode } = useSelector((state) => state.chat);
  const { paperTitle } = useSelector((state) => state.document);
  const { paperId } = useSelector((state) => state.document);
  const [paperList, setPaperList] = useState([]);
  const shortDes = {
    论文撰写: "输入主题或编辑大纲快速写完整论文",
    文献综述: "输入主题或编辑大纲开始写一篇综述论文",
    开题报告: "输入主题或大纲开始写开题报告",
    文章重组: "根据主题或大纲重组文献内容生成新文章",
    普通问答: "输入问题综合分析参考所有文献汇总解答",
    逐一问答: "输入问题逐一分析文献，每篇得到一个答案",
  };

  const { loading } = useRequest(
    () =>
      dataService.getPapers({
        type: 1,
        parent: paperId,
      }),
    {
      debounceWait: 500,
      onSuccess: (res) => {
        if (res.status === "OK") {
          setPaperList(res.data);
        }
      },
      onError: (err) => {},
    }
  );

  const fileList = paperList.map((item, index) => {
    return {
      key: item.id,
      label: <span className="select-action-menu">{item.title}</span>,
    };
  });

  const dropdownContent = (
    <div className="select-action-title">
      <FileColorIcons type="Folder" />
      <Divider
        type="vertical"
        className="select-action-divider"
      />
      <span className="select-action-label">{paperTitle}</span>
      <span className="select-action-arrow">
        <DirectionActions type="down" />
      </span>
    </div>
  );

  return (
    <div className="empty-page">
      <div className="empty-page-container">
        <div className="empty-title">
          {loading ? (
            ""
          ) : (
            <>
              {fileList.length > 0 ? (
                <>
                  <span>您正在对文件夹【</span>
                  <span style={{ color: "var(--brand-1)" }}>{paperTitle}</span>
                  <span>】下的 </span>
                  <span style={{ color: "var(--brand-1)" }}>{fileList.length} 篇 </span>
                  <span> 文献提问</span>
                </>
              ) : (
                <span style={{ color: "var(--status-error)" }}>当前文件夹内没有文献，请上传之后再进行分析</span>
              )}
            </>
          )}
        </div>
        <div className="empty-action">
          {/* <div className="select-action">
            {fileList.length > 0 ? (
              <Dropdown
                className="select-action-dropdown"
                placement="bottomLeft"
                menu={{ items: fileList }}
                align={{ offset: [-12, 13] }}
                overlayClassName={styles.selectActionDropdownOverlay}
              >
                <div className="select-action-title">
                  <FileColorIcons type="Folder" />
                  <Divider
                    type="vertical"
                    className="select-action-divider"
                  />
                  <span className="select-action-label">{paperTitle}</span>
                  <span className="select-action-arrow">
                    <DirectionActions type="down" />
                  </span>
                </div>
              </Dropdown>
            ) : (
              dropdownContent
            )}
          </div> */}
          {showQuickType && (
            <>
              <div className="empty-action-description">
                我可以分析引用文件夹内的文献内容帮你写文章、找答案哦！请选择提问方式，开始智能分析写作吧！
              </div>
              <div className="empty-action-buttons">
                {quickTypeList.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className={cls("action-button", {
                        active: quickReplyMode === item.name,
                      })}
                      onClick={() => {
                        dispatch.chat.update({
                          quickReplyMode: item.name,
                        });
                      }}
                    >
                      <div className="action-button-icon">
                        <QuickRepliesIcons type={item.name} />
                      </div>
                      <div className="action-button-name">{item.name}:</div>
                      <div className="action-button-short-des">{shortDes[item.name]}</div>
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default EmptyBubble;
