import { useCallback, useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useRequest } from "ahooks";
import { Spin, Empty } from "antd";
import classNames from "classnames";
import emptyImg from "@/assets/empty.png";
import { data as dataService } from "@/services/data";
import { flatten, find } from "lodash-es";
import { feedback } from "@/utils/index";
import ChatBody from "@/components/common/ChatUI/ChatBody";
import { MODEL_ICONS } from "@/constants/model";
import styles from "./index.module.less";
import { useHistoryPreview } from "../historyPreview.hook";

function HistoryPreview() {
  const dispatch = useDispatch();
  const { avatar = "/user.png" } = useSelector((state) => state.token);
  const { previewItem, cacheList } = useSelector((state) => state.history);
  const { id } = previewItem;
  const [messageList, setMessageList] = useState([]);
  const { onPreview } = useHistoryPreview();

  useEffect(() => {
    setMessageList([]);
    let result = find(cacheList, (el) => el.id === id);
    if (result) {
      setMessageList(result.messageList);
      return;
    }
    id && getSessionById();
  }, [previewItem]);

  const closePreview = useCallback(() => {
    dispatch.history.closePreview();
  });

  const { loading, run: getSessionById } = useRequest(() => dataService.getSession(id), {
    manual: true,
    onSuccess: (res) => {
      if (res.status === "OK") {
        const { messages: msgs, scenes, paper } = res.data;
        const { status, paper_info } = paper || {};
        const results = msgs.map((msg, index) => {
          let messageType, _question, config;
          _question = msg.question;
          config = msg.config;
          messageType = msg.config === null ? "text" : "file";
          const rtn = [
            {
              _id: `${msg.id}-question`,
              id: `${msg.id}-question`,
              type: "normal-message",
              content: {
                createdAt: msg.created_at,
                text: _question,
                config: config,
                messageId: msg.id,
                message_type: messageType,
                isLast: index === msgs.length - 1,
                question_type: msg.question_type,
              },
              user: {
                avatar: avatar,
              },
              position: "right",
            },
          ];
          if (index < msgs.length - 1 || msg.answers[0].answer) {
            rtn.push({
              _id: `${msg.id}-answer`,
              id: `${msg.id}-answer`,
              type: "answers-message",
              content: {
                createdAt: msg.modified_at,
                messageId: msg.id,
                answers: msg.answers,
                isLast: index === msgs.length - 1,
                model: msg.model,
                question_type: msg.question_type,
                question_text: msg.question,
              },
              user: { avatar: MODEL_ICONS[msg.model] },
              position: "left",
            });
          }
          return rtn;
        });
        let newResult = [...flatten(results)];
        setMessageList(newResult);
        cacheList.push({
          id,
          messageList: newResult,
        });
      } else {
        feedback(res.data);
      }
    },
    onError: (err) => {
      if (err.code === 404) {
        setMessageList([]);
      } else {
        feedback(err.data);
      }
    },
  });

  const enterCurrent = useCallback(() => {
    onPreview(previewItem);
  }, [previewItem]);

  return (
    <div className={classNames(styles.HistoryPreview, "HistoryPreview")}>
      <div className="previewTitle">会话预览</div>
      {loading ? (
        <Spin
          tip="加载中..."
          style={{ display: "block", marginTop: "100px" }}
        />
      ) : messageList?.length ? (
        <ChatBody
          messageList={messageList}
          setMessageList={setMessageList}
        />
      ) : (
        <Empty
          style={{ marginTop: "100px" }}
          description={"暂无内容"}
          image={emptyImg}
        />
      )}
      <div className={classNames("previewAction")}>
        <div
          className="closeBtn"
          onClick={closePreview}
        >
          关闭预览
        </div>
        <div
          className="previewBtn"
          onClick={enterCurrent}
        >
          进入当前会话
        </div>
      </div>
    </div>
  );
}

export default HistoryPreview;
