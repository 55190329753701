import { groupBy } from "lodash-es";
import { useRequest } from "ahooks";
import { data as dataService } from "@/services";
import { feedback } from "@/utils/index";
// import { useEffect } from "react";
// import { useSelector } from "react-redux";

const useLayoutInit = ({ dispatch, promptListCached, setPromptListCached }) => {
  // const { jwt } = useSelector((state) => state.token);

  // useEffect(() => {
  //   if (!jwt) return;
  //   getSelectedPromptForce();
  // }, [jwt]);

  // 全部的prompts
  const { run: getSelectedPrompt } = useRequest(
    () => {
      if (
        promptListCached &&
        promptListCached.dataset &&
        promptListCached.datetime &&
        Date.now() - promptListCached.datetime < 60 * 60 * 1000
      ) {
        return new Promise((resolve) => {
          resolve({
            code: 200,
            status: "OK",
            data: promptListCached.dataset,
          });
        }, 0);
      }
      return dataService.getSelectedPrompt();
    },
    {
      throttleWait: 1000,
      cacheKey: `chat-prompt-list`,
      onSuccess: (res) => {
        if (res.data.length) {
          setPromptListCached({
            dataset: res.data,
            datetime: Date.now(),
          });
        }
        const prompts = res.data.reduce((acc, cur) => {
          acc[cur.short_name] = cur.description_list;
          return acc;
        }, {});
        const tutorUrls = res.data.reduce((acc, cur) => {
          acc[cur.short_name] = cur.tutor_url || "";
          return acc;
        }, {});
        const faqUrls = res.data.reduce((acc, cur) => {
          acc[cur.short_name] = cur.faq_url || "";
          return acc;
        }, {});
        const placeholderList = res.data.reduce((acc, cur) => {
          acc[cur.short_name] = cur.placeholder || "";
          return acc;
        }, {});
        const group = Object.entries(groupBy(res.data, "type"));
        dispatch.app.update({
          chatPromptList: prompts,
          chatTutorUrlList: tutorUrls,
          chatFaqUrlList: faqUrls,
          chatPromptGroup: group,
          chatPromptLoading: false,
          chatPlaceholderList: placeholderList,
        });
      },
      onError: (err) => {
        feedback(err.data);
      },
    }
  );

  const getSelectedPromptForce = () => {
    promptListCached = null;
    getSelectedPrompt();
  };

  return {
    getSelectedPrompt,
    getSelectedPromptForce,
  };
};

export default useLayoutInit;
