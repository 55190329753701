import { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import BubbleAction from "./BubbleAction/index";
import bubbleActionFilter from "./BubbleAction/filter";
import TextBubble from "./Bubble/TextBubble";
import PlaceholderBubble from "./PlaceholderBubble";
import TimesErrorBubble from "./Bubble/TimesErrorBubble";
import TotalLengthErrorBubble from "./Bubble/TotalLengthErrorBubble";
import LoadingBubble from "./Bubble/LoadingBubble";
import TypingBubble from "./Bubble/TypingBubble";
import ImageBubble from "./Bubble/ImageBubble";
import ArxivBubble from "./ArxivBubble";
import PubmedBubble from "./PubmedBubble";
import CNKIBubble from "./CNKIBubble";
import ScienceBubble from "./ScienceBubble";
import OutlineBubble from "./OutlineBubble";
import RefineBubble from "./RefineBubble";
import PPTBubble from "./PPTBubble";
import VideoBubble from "./VideoBubble";
import ResolveMessage from "./ResolveMessage";

function AnswersMessage({ content, setText, messageList, setMessageList, onRefineSend, onOutlineSend }) {
  const { showPreview } = useSelector((state) => state.history);
  const { answers } = content;
  const [currentIndex, setCurrentIndex] = useState(0);
  const outlineRef = useRef(null);
  const { text, actionType, actionText, actionList } = bubbleActionFilter(
    answers[currentIndex]?.message_type,
    answers[currentIndex]?.answer,
    content?.question_type,
    "left",
    answers[currentIndex]?.status
  );

  // useEffect(() => {
  //   setCurrentIndex(answers?.length - 1);
  // }, [answers]);

  // 如果当前这个answer还没有内容，先判断为回答中的问题
  if (!answers[currentIndex]?.answer && !showPreview) {
    return (
      <ResolveMessage
        key={content?.messageId}
        setComposerText={setText}
        messageId={answers[currentIndex]?.id}
      />
    );
  }

  return (
    <BubbleAction
      messageId={content.messageId}
      answerId={answers[currentIndex]?.id}
      text={text}
      status={answers[currentIndex]?.status}
      setComposerText={setText}
      actionType={actionType}
      actionText={actionText}
      actionList={actionList}
      outlineRef={outlineRef}
    >
      <NormalMessageBubble
        text={answers[currentIndex]?.answer}
        messageType={answers[currentIndex]?.message_type}
        createdAt={answers[currentIndex]?.modified_at}
        status={answers[currentIndex]?.status}
        isContent={answers[currentIndex]?.isContent}
        config={answers[currentIndex]?.config}
        answerId={answers[currentIndex]?.id}
        questionType={content?.question_type}
        placeholderType={content?.placeholder_type}
        showButtons={content?.showButtons}
        isLast={content?.isLast}
        messageId={content?.messageId}
        position={"left"}
        finished={true}
        outlineRef={outlineRef}
        messageList={messageList}
        onOutlineSend={onOutlineSend}
        setMessageList={setMessageList}
        onRefineSend={onRefineSend}
        setComposerText={setText}
        totalLength={answers.length}
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
        question_text={content?.question_text}
      />
    </BubbleAction>
  );
}

function NormalMessageBubble({
  text,
  createdAt,
  status,
  finished,
  setComposerText,
  messageId,
  messageType,
  position,
  isLast,
  questionType,
  placeholderType,
  isContent,
  config,
  outlineRef,
  showButtons,
  messageList,
  onOutlineSend,
  onRefineSend,
  setMessageList,
  processingText,
  messageReText,
  totalLength,
  currentIndex,
  setCurrentIndex,
  answerId,
  question_text,
}) {
  switch (messageType) {
    case "text":
      return (
        <TextBubble
          questionType={questionType}
          text={text}
          createdAt={createdAt}
          status={status}
          finished={finished}
          setComposerText={setComposerText}
          messageId={messageId}
          position={position}
          isLast={isLast}
          isContent={isContent}
          config={config}
          processingText={processingText}
          messageReText={messageReText}
          totalLength={totalLength}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
          answerId={answerId}
          question_text={question_text}
        />
      );
    case "outline":
      return (
        <OutlineBubble
          questionType={questionType}
          text={text}
          createdAt={createdAt}
          status={status}
          finished={finished}
          setComposerText={setComposerText}
          messageId={messageId}
          position={position}
          isLast={isLast}
          isContent={isContent}
          ref={outlineRef}
          messageList={messageList}
          onOutlineSend={onOutlineSend}
          setMessageList={setMessageList}
          showButtons={showButtons}
          config={config}
          totalLength={totalLength}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
        />
      );
    case "ppt":
      return (
        <PPTBubble
          questionType={questionType}
          text={text}
          createdAt={createdAt}
          status={status}
          finished={finished}
          setComposerText={setComposerText}
          messageId={messageId}
          position={position}
          isLast={isLast}
          isContent={isContent}
          totalLength={totalLength}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
        />
      );
    case "refine":
      return (
        <RefineBubble
          messageId={messageId}
          position={position}
          onRefineSend={onRefineSend}
        />
      );
    case "video":
      return (
        <VideoBubble
          questionType={questionType}
          text={text}
          createdAt={createdAt}
          status={status}
          finished={finished}
          setComposerText={setComposerText}
          messageId={messageId}
          position={position}
          isLast={isLast}
          isContent={isContent}
          messageReText={messageReText}
          totalLength={totalLength}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
          answerId={answerId}
        />
      );
    case "img":
      return (
        <ImageBubble
          questionType={questionType}
          text={text}
          finished={finished}
          createdAt={createdAt}
          setComposerText={setComposerText}
          messageId={messageId}
          position={position}
          isLast={isLast}
          messageReText={messageReText}
          totalLength={totalLength}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
          answerId={answerId}
        />
      );
    case "file":
      return (
        <TextBubble
          fileList={config}
          questionType={questionType}
          text={text}
          createdAt={createdAt}
          status={status}
          finished={finished}
          setComposerText={setComposerText}
          messageId={messageId}
          position={position}
          isLast={isLast}
          config={config}
          processingText={processingText}
          messageReText={messageReText}
          answerId={answerId}
          question_text={question_text}
        />
      );
    case "arxiv-scholar":
      return (
        <ArxivBubble
          text={text}
          status={status}
          finished={finished}
          createdAt={createdAt}
          setComposerText={setComposerText}
          messageId={messageId}
          questionType={questionType}
          isLast={isLast}
          messageReText={messageReText}
          totalLength={totalLength}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
          answerId={answerId}
        />
      );
    case "pubmed-scholar":
      return (
        <PubmedBubble
          text={text}
          status={status}
          finished={finished}
          createdAt={createdAt}
          setComposerText={setComposerText}
          messageId={messageId}
          questionType={questionType}
          isLast={isLast}
          messageReText={messageReText}
          totalLength={totalLength}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
          answerId={answerId}
        />
      );
    case "science-scholar":
      return (
        <ScienceBubble
          text={text}
          status={status}
          finished={finished}
          createdAt={createdAt}
          setComposerText={setComposerText}
          messageId={messageId}
          isLast={isLast}
        />
      );
    case "cnki-scholar":
      return (
        <CNKIBubble
          text={text}
          status={status}
          finished={finished}
          createdAt={createdAt}
          setComposerText={setComposerText}
          messageId={messageId}
          isLast={isLast}
          messageReText={messageReText}
          totalLength={totalLength}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
          answerId={answerId}
        />
      );
    case "message-loading":
      return <LoadingBubble />;
    case "message-typing":
      return <TypingBubble />;
    case "total-length":
      return <TotalLengthErrorBubble error={text} />;
    case "no-times":
      return <TimesErrorBubble error={text} />;
    case "plugin-placeholder":
      return <PlaceholderBubble type={placeholderType} />;

    default:
      return (
        <TextBubble
          questionType={questionType}
          text={text}
          status={status}
          finished={finished}
          createdAt={createdAt}
          setComposerText={setComposerText}
          messageId={messageId}
          position={position}
          isLast={isLast}
          isContent={isContent}
          config={config}
          processingText={processingText}
          messageReText={messageReText}
          totalLength={totalLength}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
          answerId={answerId}
          question_text={question_text}
        />
      );
  }
}
export default AnswersMessage;
