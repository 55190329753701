export const changeTheme = (dark) => {
  document.documentElement.setAttribute("data-theme", dark ? "dark" : "light");
  document.querySelector(".tiptap.ProseMirror")?.setAttribute("data-theme", dark ? "dark" : "light");
  let link = document.getElementById("theme-style");
  const href = window.location.origin + (dark ? "/themes/antd.dark.css" : "/themes/antd.css");
  if (!link) {
    link = document.createElement("link");
    link.id = "theme-style";
    link.rel = "stylesheet";
    link.href = href;
    document.head.appendChild(link);
    return;
  }
  if (link.href !== href) {
    link.href = href;
  }
};
