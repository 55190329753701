import { useCallback, useEffect, memo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMatch } from "react-router-dom";
import copy from "copy-to-clipboard";
import cls from "classnames";
import { message, Popconfirm, Tooltip } from "antd";
import { LoadingOutlined, CheckOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import { data as dataService } from "@/services/data";
import { feedback } from "@/utils/index";
import styles from "./index.module.less";
import ToolbarIcons from "@/icons/ToolbarIcons";
import useScreenSize from "@/hooks/useScreenSize";

// 论文检索结果展示的tools
const limitedTools = ["重新回答", "删除"];

function BubbleToolbar({
  text,
  createdAt,
  setComposerText,
  messageId,
  position,
  status,
  isLast,
  // 判断是否是论文检索结果
  isScholar,
  isImage,
  isBasic,
  totalLength,
  currentIndex,
  setCurrentIndex,
  answerId = "",
  allowReAnswer = true,
}) {
  const dispatch = useDispatch();
  const match = useMatch("/chat");

  const { checking } = useSelector((state) => state.user);
  const { showPreview } = useSelector((state) => state.history);
  const [loading, setLoading] = useState(false);
  const [reanswering, setReanswering] = useState(false);
  const [popOpen, setPopOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  const [writed, setWrited] = useState(false);
  const [audio] = useState(new Audio());
  const { middle } = useScreenSize();

  useEffect(() => {
    audio.addEventListener("ended", () => setLoading(false));
    return () => {
      audio.pause();
      audio.removeEventListener("ended", () => setLoading(false));
    };
  }, []);

  const copyHandler = useCallback(
    (text) => {
      if (copied) return;
      if (copy(text)) {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 3000);
      } else {
        message.error("无法复制内容，请确认浏览器支持");
      }
    },
    [copied]
  );

  const writeHandler = useCallback(() => {
    if (writed) return;
    const comIpt = document.querySelector(".Composer-input");
    comIpt.focus();
    setComposerText(text);
    setWrited(true);
    setTimeout(() => {
      setWrited(false);
    }, 3000);
  }, [text, setComposerText, writed]);

  const reanswerHandler = useCallback(async () => {
    if (!!messageId && !reanswering) {
      setReanswering(true);
      try {
        await dataService.reAnswer(messageId);
        dispatch[match ? "chat" : "document"].version();
      } catch (err) {
        feedback(err.data);
      } finally {
        setReanswering(false);
      }
    }
  }, [reanswering, match]);

  const deleteHandler = useCallback(async () => {
    if (messageId) {
      try {
        await dataService.removeAnswer(messageId);
        dispatch[match ? "chat" : "document"].version();
        message.success("删除成功！");
      } catch (err) {
        feedback(err.data);
      }
    }
  }, [match]);

  const exportHandler = useCallback(() => {
    try {
      let src = `https://chat.phitrellis.com/api/answer/${answerId}/download_docx/`;
      window.open(src, "_blank");
    } catch (err) {
      feedback(err.data || "内容导出失败！");
    }
    // try {
    //   const date = Date.now();
    //   let iframe = document.createElement("iframe");
    //   iframe.src = `https://chat.phitrellis.com/api/answer/${answerId}/download_docx/`;
    //   iframe.style.display = "none";
    //   iframe.id = `iframe-${date}`;
    //   document.body.appendChild(iframe);
    //   setTimeout(() => {
    //     document.body.removeChild(iframe);
    //   }, 1000);
    // } catch (err) {
    //   feedback(err.data || "内容导出失败！");
    // }
  }, [answerId]);

  const onUpdateAnswerIndex = useCallback(
    (delta) => {
      if (currentIndex + delta < 0 || currentIndex + delta > totalLength - 1) {
        return;
      }
      setCurrentIndex(currentIndex + delta);
    },
    [currentIndex, totalLength]
  );

  // 重新回答之后，自动翻到最后一个
  useEffect(() => {
    if (setCurrentIndex) {
      setCurrentIndex(totalLength - 1);
    }
  }, [setCurrentIndex, totalLength]);

  const menuList = [
    {
      title: copied ? "复制成功" : "复制",
      tip: "复制原始内容",
      handler: () => copyHandler(text),
      icon: copied ? <CheckOutlined style={{ color: "var(--status-success)" }} /> : <ToolbarIcons type="copyIcon" />,
    },
    {
      title: writed ? "写入成功" : "写入输入框",
      tip: "内容写入输入框，修改后继续提问",
      handler: writeHandler,
      icon: writed ? <CheckOutlined style={{ color: "var(--status-success)" }} /> : <ToolbarIcons type="toInputIcon" />,
    },
    {
      title: "重新回答",
      tip: "重新获取一次答案",
      handler: reanswerHandler,
      icon: reanswering ? <LoadingOutlined /> : <ToolbarIcons type="replyIcon" />,
    },
    {
      title: "删除",
      tip: "删除这条对话",
      handler: () => setPopOpen(true),
      icon: <ToolbarIcons type="deleteIcon" />,
    },
    {
      title: "导出word",
      tip: "保持看到的格式，导出为word文档",
      handler: exportHandler,
      icon: <ToolbarIcons type="exportIcon" />,
    },
  ];

  return (
    <div
      className={cls("BubbleToolbar", position === "left" ? styles.leftBubbleToolbar : styles.rightBubbleToolbar)}
      style={{ zIndex: 1, justifyContent: showPreview ? "flex-end" : "space-between" }}
    >
      {!showPreview && (
        <div className="BubbleToolbar-btns">
          {position === "left" && totalLength > 1 && (
            <div className="pages">
              <span
                className={cls("page-item", currentIndex === 0 ? "disabled" : null)}
                onClick={() => {
                  onUpdateAnswerIndex(-1);
                }}
              >
                <LeftOutlined />
              </span>
              <span className="page-info">
                {currentIndex + 1} / {totalLength}
              </span>
              <span
                className={cls("page-item", currentIndex + 1 === totalLength ? "disabled" : null)}
                onClick={() => {
                  onUpdateAnswerIndex(1);
                }}
              >
                <RightOutlined />
              </span>
            </div>
          )}
          {menuList
            .filter((item) => (position === "left" ? item : item.title !== "删除"))
            .filter((item) => (position === "left" && isLast ? item : item.title !== "重新回答"))
            .filter((item) => (position === "left" && status === 2 ? item : item.title !== "导出word"))
            // 论文检索
            .filter((item) => (isScholar ? limitedTools.includes(item.title) : item))
            .filter((item) => (isImage ? limitedTools.includes(item.title) : item))
            .filter((item) => (isBasic ? limitedTools.includes(item.title) : item))
            // 重新回答
            .filter((item) => (item.title !== "重新回答" ? item : allowReAnswer ? item : false))
            .map(({ handler, icon, title, tip }, index) => {
              return (
                <Tooltip
                  key={`toolbar-item-${index}`}
                  title={tip}
                  overlayInnerStyle={{ userSelect: "none" }}
                  getPopupContainer={(triggerNode) => triggerNode}
                >
                  <Popconfirm
                    //给删除按钮加popconfirm
                    open={title === "删除" && popOpen}
                    onCancel={() => {
                      setPopOpen(false);
                    }}
                    onOpenChange={(open) => {
                      if (!open) setPopOpen(false);
                    }}
                    onConfirm={deleteHandler}
                    title="确定要删除该条消息吗？"
                    arrowPointAtCenter
                  >
                    <div
                      onClick={handler}
                      className="menu-item"
                    >
                      <div className="menu-item-icon">{icon}</div>
                      {middle && <div className="menu-item-title">{title}</div>}
                    </div>
                  </Popconfirm>
                </Tooltip>
              );
            })}
        </div>
      )}
      <div className="BubbleToolbar-exts">
        <span className="time">{createdAt}</span>
      </div>
    </div>
  );
}

export default BubbleToolbar;
