export const MODEL_TIPS = {
  GPT4_MINI: "响应速度快，每次普通对话消耗 1 点，登录后免费(不含文献)",
  CLAUDE_V3_OPUS: "擅长文字，每次普通对话消耗 30 点",
  CLAUDE_V3_SONNET: "擅长代码等，每次普通对话消耗 20 点",
  CLAUDE_V3_HAIKU: "响应速度快，每次普通对话消耗 1 点",
  GPT4: "擅长文字，每次普通对话消耗 20 点",
  // O1_MINI: "擅长推理、代码，知识略有欠缺，每次普通对话消耗 20 点",
  O1: "擅长推理、代码，效果极佳，每次普通对话消耗 100 点",
  ERNIE_TURBO: "中文支持好，回答速度快，登录后免费(不含文献)",
  GEMINI_FLASH: "回答速度快，每次普通对话消耗 20 点",
  DEEPSEEK: "国产之光，中文支持好，智商高，回复速度快，每次普通对话消耗 20 点",
  DEEPSEEK_R1: "国产之光，推理大模型，中文支持好，智商高，回复速度快，每次普通对话消耗 50 点",
  O3_MINI: "OpenAI最新推理大模型，据说比o1更强，每次普通对话消耗 20 点",
  GROK3: "Grok 3, xai 最新大模型，每次普通对话消耗 20 点",
};

export const MODEL_LIST = [
  {
    label: "OpenAI o1",
    value: "O1",
    badge: {
      label: "推荐",
      bgcolor: "#ff2f00",
    },
  },
  {
    label: "OpenAI o3 mini",
    value: "O3_MINI",
  },
  {
    label: "GPT4o",
    value: "GPT4",
  },
  {
    label: "DeepSeek R1",
    value: "DEEPSEEK_R1",
    badge: {
      label: "推荐",
      bgcolor: "#ff2f00",
    },
  },
  {
    label: "DeepSeek",
    value: "DEEPSEEK",
    badge: {
      label: "NEW",
      bgcolor: "#ff2f00",
    },
  },
  {
    label: "Gemini",
    value: "GEMINI_FLASH",
  },
  {
    label: "Haiku",
    value: "CLAUDE_V3_HAIKU",
  },
  {
    label: "Sonnet",
    value: "CLAUDE_V3_SONNET",
  },
  {
    label: "Opus",
    value: "CLAUDE_V3_OPUS",
  },
  {
    label: "GPT4o mini",
    value: "GPT4_MINI",
    badge: {
      label: "FREE",
      bgcolor: "#ff2f00",
    },
  },
  {
    label: "文心一言",
    value: "ERNIE_TURBO",
    badge: {
      label: "FREE",
      bgcolor: "#ff2f00",
    },
  },
  {
    label: "Grok 3",
    value: "GROK",
  },
];

export const getModelName = (key) => {
  const item = MODEL_LIST.find((x) => x.value === key);
  return item && item.label ? item.label : key;
};

export const MODEL_ICONS = {
  GPT4_MINI: "/gpt35.png",
  O1_MINI: "/o1.png",
  O1: "/o1.png",
  O3_MINI: "/o3_mini.png",
  CLAUDE_V3_HAIKU: "/haiku.jpeg",
  CLAUDE_V3_SONNET: "/sonnet.jpeg",
  CLAUDE_V3_OPUS: "/opus.jpeg",
  GPT4: "/gpt4.png",
  ERNIE_TURBO: "/ERNIE_TURBO.jpg",
  GEMINI_FLASH: "/GEMINI.png",
  DEEPSEEK: "/ds.png",
  DEEPSEEK_R1: "/ds_r1.png",
  SYSTEM: "/logo.png",
  GROK: "/grok.svg",
};

export const MODEL_FEATURES_LIST = {
  GPT4_MINI: [
    {
      description: "GPT4o mini，适用简单任务，响应速度快",
      icon: "",
    },
    {
      description: "登录后免费(不含文献)",
      icon: "",
    },
    {
      description: "所有月卡会员无限制使用",
      icon: "",
    },
    {
      description: "其他用户每次普通对话消耗 1 点",
      icon: "",
    },
    {
      description: "不支持上传图片",
      icon: "",
    },
  ],
  CLAUDE_V3_OPUS: [
    {
      description: "擅长文字处理",
      icon: "",
    },
    {
      description: "高级月卡无限制使用",
      icon: "",
    },
    {
      description: "其他用户每次普通对话消耗 30 点",
      icon: "",
    },
    {
      description: "支持上传图片",
      icon: "",
    },
  ],
  CLAUDE_V3_SONNET: [
    {
      description: "擅长代码",
      icon: "",
    },
    {
      description: "月卡用户、高级月卡无限制使用",
      icon: "",
    },
    {
      description: "其他用户每次普通对话消耗 20 点",
      icon: "",
    },
    {
      description: "支持上传图片",
      icon: "",
    },
  ],
  CLAUDE_V3_HAIKU: [
    {
      description: "响应速度快",
      icon: "",
    },
    {
      description: "所有月卡会员无限制使用",
      icon: "",
    },
    {
      description: "其他用户每次普通对话消耗 1 点",
      icon: "",
    },
    {
      description: "支持上传图片",
      icon: "",
    },
  ],
  GPT4: [
    {
      description: "GPT4o, OpenAI 上一代最强大模型",
      icon: "",
    },
    {
      description: "月卡用户，高级月卡无限制使用",
      icon: "",
    },
    {
      description: "其他用户每次普通对话消耗 20 点",
      icon: "",
    },
    {
      description: "适合处理文字、代码、写作等复杂问题",
      icon: "",
    },
    {
      description: "支持上传图片",
      icon: "",
    },
  ],
  O1: [
    {
      description: "OpenAI o1，当前最强大模型，博士生水平",
      icon: "",
    },
    {
      description: "擅长推理、代码、写论文，效果极佳",
      icon: "",
    },
    {
      description: "高级月卡每月 100 次普通对话",
      icon: "",
    },
    {
      description: "其他用户每次普通对话消耗 100 点",
      icon: "",
    },
    {
      description: "支持上传图片",
      icon: "",
    },
    {
      description: "强烈推荐",
      icon: "",
    },
  ],
  O3_MINI: [
    {
      description: "OpenAI o3 mini，最新推理大模型",
      icon: "",
    },
    {
      description: "擅长推理、代码、写论文",
      icon: "",
    },
    {
      description: "月卡用户、高级月卡无限制使用",
      icon: "",
    },
    {
      description: "其他用户每次普通对话消耗 20 点",
      icon: "",
    },
    {
      description: "不支持上传图片",
      icon: "",
    },
  ],
  ERNIE_TURBO: [
    {
      description: "中文支持好，响应速度快，适用简单任务",
      icon: "",
    },
    {
      description: "登录后免费(不含文献)",
      icon: "",
    },
    {
      description: "所有月卡会员无限制使用",
      icon: "",
    },
    {
      description: "其他用户每次普通对话消耗 1 点",
      icon: "",
    },
    {
      description: "不支持上传图片",
      icon: "",
    },
  ],
  GEMINI_FLASH: [
    {
      description: "Gemini 2.0, Google最新人工智能大语言模型",
      icon: "",
    },
    {
      description: "回答速度快，上下文支持好，智力中等",
      icon: "",
    },
    {
      description: "月卡用户、高级月卡无限制使用",
      icon: "",
    },
    {
      description: "其他用户每次普通对话消耗 20 点",
      icon: "",
    },
    {
      description: "支持上传图片",
      icon: "",
    },
  ],
  DEEPSEEK: [
    {
      description: "DeepSeek V3 0324, 国产之光，赶超GPT4.5",
      icon: "",
    },
    {
      description: "回答速度快，智商高",
      icon: "",
    },
    {
      description: "月卡用户、高级月卡无限制使用",
      icon: "",
    },
    {
      description: "其他用户每次普通对话消耗 20 点",
      icon: "",
    },
    {
      description: "不支持上传图片",
      icon: "",
    },
  ],
  DEEPSEEK_R1: [
    {
      description: "DeepSeek R1, 国产之光，推理大模型，中文支持好",
      icon: "",
    },
    {
      description: "回答速度快，智商高",
      icon: "",
    },
    {
      description: "高级月卡无限制使用",
      icon: "",
    },
    {
      description: "其他用户每次普通对话消耗 50 点",
      icon: "",
    },
    {
      description: "不支持上传图片",
      icon: "",
    },
  ],
  GROK: [
    {
      description: "Grok 3, xai最新大模型",
      icon: "",
    },
    {
      description: "回答速度快，智商高",
      icon: "",
    },
    {
      description: "月卡用户、高级月卡无限制使用",
      icon: "",
    },
    {
      description: "其他用户每次普通对话消耗 20 点",
      icon: "",
    },
    {
      description: "支持上传图片",
      icon: "",
    },
  ],
};
