import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import cls from "classnames";
import { useSearchParams, useNavigate } from "react-router-dom";
import useScreenSize from "@/hooks/useScreenSize";
import WindowHeader from "@/components/common/WindowHeader";
import ChatWindow from "../ChatWindow";
import Noticebar from "./NoticeBar";
import ChatHome from "../ChatHome";
import MobileEditableTitle from "@/components/common/WindowHeader/MobileEditableTitle";
import styles from "./index.module.less";

function WindowBody() {
  const { quickReply, sessionId, chatAtHome } = useSelector((state) => state.chat);
  const { chatPromptList, chatTutorUrlList, chatFaqUrlList, chatPlaceholderList } = useSelector((state) => state.app);
  const { messageList } = useSelector((state) => state.user);
  const { middle } = useScreenSize();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [param, setParam] = useSearchParams();
  const urlId = param.get("id");

  // 新会话处理路由参数
  useEffect(() => {
    if (!sessionId) return;
    navigate(`/chat?id=${sessionId}`, { replace: true });
  }, [sessionId]);

  useEffect(() => {
    urlId && dispatch.chat.update({ sessionId: urlId, chatAtHome: false });
    if (param.get("quickReply")) {
      dispatch.chat.update({
        quickReply: param.get("quickReply"),
        replyVersion: Date.now(),
        chatAtHome: false,
      });
    }
  }, []);

  return (
    <div className={styles.windowBd}>
      <div className="window-body-container">
        <div className={cls("window-body-inner")}>
          <WindowHeader />
          {messageList.length > 0 && <Noticebar messageList={messageList} />}
          {/* 手机版title */}
          {!middle && <MobileEditableTitle />}
          {chatAtHome ? (
            <ChatHome />
          ) : (
            <ChatWindow
              prompts={chatPromptList}
              tutors={chatTutorUrlList}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default WindowBody;
