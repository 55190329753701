import { Tooltip, Button as AntdButton, Upload, message } from "antd";
import { isEmpty } from "lodash-es";
import styles from "./index.module.less";
import { getUploadConfig } from "./uploadConfig";
import { flushSync } from "react-dom";
import CustomTip from "@/components/common/CustomTip";

function UploadButton({ quickReply, setFileList, setSendDisable, fileList, callback }) {
  let config = getUploadConfig(quickReply);
  const handleChange = (info) => {
    const { fileList } = info;
    const newFileList = fileList
      .map((file) => {
        return {
          ...file,
          loading: !file.response && !file.uploadError,
          error: file.uploadError,
          isImage: file.type.indexOf("image/") > -1,
        };
      })
      .filter((file) => !file.error);
    flushSync(() => {
      setFileList([...newFileList]);
    });
    if (newFileList.length > config.maxCount) {
      message.error(`最多上传${config.maxCount}个文件`);
    }
    const loading = !isEmpty(newFileList.filter((file) => file.loading));
    if (!loading && config.callback) {
      callback(newFileList);
    }
    setSendDisable(loading);
  };

  return (
    <CustomTip content={config.title}>
      <Upload
        accept={config.accept}
        showUploadList={false}
        multiple={true}
        fileList={fileList}
        className={styles.customToolbarUpload}
        onChange={handleChange}
        action={"/api/usermedia/upload_media/"}
        maxCount={config.maxCount}
        beforeUpload={(file) => {
          const isImage = file.type.indexOf("image/") > -1;
          const size = isImage ? 3 : config.limitSize;
          const type = isImage ? "图片" : "文件";
          if (file.size > size * 1024 * 1024) {
            file.uploadError = true;
            message.error(`${file.name}${type}大小不能超过 ${size} MB`);
            return false;
          }
        }}
      >
        <AntdButton
          type="text"
          icon={config.icon}
          size="large"
        />
      </Upload>
    </CustomTip>
  );
}

export default UploadButton;
