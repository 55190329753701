import { Popover } from "antd";
import { TagTwoTone } from "@ant-design/icons";

function CustomTip({ placement = "top", content, trigger = "hover", children, showIcon = true }) {
  return (
    <Popover
      placement={placement}
      overlayInnerStyle={{
        borderRadius: 10,
      }}
      content={
        <div className="custom-tips">
          {Array.isArray(content) &&
            content?.map((item, index) => {
              return (
                <div
                  key={`${index}-tip`}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 8,
                    fontSize: 13,
                  }}
                >
                  {showIcon && <TagTwoTone />}
                  <span>{item}</span>
                </div>
              );
            })}
        </div>
      }
      trigger={trigger}
    >
      {children}
    </Popover>
  );
}

export default CustomTip;
