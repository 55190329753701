import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip, Dropdown, Button, Modal, message } from "antd";
import { feedback } from "@/utils/index";
import { data as dataService } from "@/services/data";
import ThingIcons from "@/icons/ThingIcons";
import styles from "./index.module.less";
import { ExclamationCircleOutlined } from "@ant-design/icons";

function HistoryAction({ setOrdering, setBatchDel, isBatchDel, batchDeleteList, updateBatchSelected, onBatchDelete }) {
  const { tokenInfo } = useSelector((state) => state.user);
  const { sessionId: chatSessionId } = useSelector((state) => state.chat);
  const { sessionId: documentSessionId } = useSelector((state) => state.document);
  const dispatch = useDispatch();
  const { session_order, user } = tokenInfo;

  const items = [
    {
      key: "-created_at",
      label: (
        <Button
          type="text"
          onClick={() => {
            setOrdering("-created_at");
            updateInfo("-created_at");
          }}
        >
          创建时间
        </Button>
      ),
    },
    {
      key: "-modified_at",
      label: (
        <Button
          type="text"
          onClick={() => {
            setOrdering("-modified_at");
            updateInfo("-modified_at");
          }}
        >
          修改时间
        </Button>
      ),
    },
  ];

  const onRemove = useCallback(
    async (e) => {
      e.stopPropagation();
      if (batchDeleteList.length === 0) {
        message.warning("请选择会话");
        return;
      }
      Modal.confirm({
        title: "删除",
        icon: <ExclamationCircleOutlined />,
        content: `确认删除会话？`,
        okText: "确认",
        cancelText: "取消",
        onOk: async () => {
          try {
            const res = await dataService.bulkRemoveSession({ ids: batchDeleteList });
            if (res.status === "OK") {
              message.success("操作成功");
              if (batchDeleteList.includes(chatSessionId)) {
                dispatch.chat.resetSession();
              } else if (batchDeleteList.includes(documentSessionId)) {
                dispatch.document.resetSession();
              }
              onBatchDelete();
            }
          } catch (err) {
            feedback(err.data);
          }
        },
      });
    },
    [batchDeleteList]
  );

  const updateInfo = useCallback(async (value) => {
    try {
      const res = await dataService.updateTokenInfo(user, { session_order: value });
      dispatch.user.updateOrdering(value);
    } catch (err) {
      feedback(err.data);
    }
  }, []);

  return (
    <div className={styles.historyAction}>
      <div className="history-delete">
        {isBatchDel ? (
          <Button
            size="small"
            type="link"
            onClick={onRemove}
          >
            删除
          </Button>
        ) : null}
        <Tooltip title={isBatchDel ? "取消批量删除" : "批量删除"}>
          <Button
            size="small"
            type="link"
            icon={<ThingIcons type={isBatchDel ? "batchDelCancel" : "batchDel"} />}
            onClick={() => {
              isBatchDel && updateBatchSelected([]);
              setBatchDel(!isBatchDel);
            }}
          ></Button>
        </Tooltip>
      </div>
      <div className="history-filter">
        <Dropdown
          menu={{ items, selectable: true, defaultSelectedKeys: session_order }}
          overlayClassName={styles.historyFilter}
        >
          <Tooltip title="排序">
            <Button
              size="small"
              type="link"
              icon={<ThingIcons type="ordering" />}
            ></Button>
          </Tooltip>
        </Dropdown>
      </div>
    </div>
  );
}

export default HistoryAction;
