export const down = (
  <svg
    t="1741277735511"
    className="icon"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    p-id="2884"
    width="14"
    height="14"
  >
    <path
      d="M548.352 730.624l394.24-360.96c21.504-19.456 23.04-53.248 3.072-74.24-19.456-21.504-53.248-23.04-74.24-3.072l-358.912 328.704L153.088 291.84c-21.504-19.456-54.784-17.92-74.24 3.584-19.456 21.504-17.92 54.784 3.584 74.24l394.752 360.448c1.024 0.512 1.536 1.536 2.56 2.048 19.456 16.896 49.152 16.384 68.608-1.536z"
      p-id="2885"
      fill="currentColor"
    ></path>
  </svg>
);

export const updown = (
  <svg
    t="1742730738846"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    p-id="5062"
    width="20"
    height="20"
  >
    <path
      d="M746.965333 319.701333a42.666667 42.666667 0 0 1-60.330666 60.330667L512 205.44 337.365333 380.032a42.666667 42.666667 0 0 1-56.32 3.541333l-4.010666-3.541333a42.666667 42.666667 0 0 1 0-60.330667l204.8-204.8a42.666667 42.666667 0 0 1 60.330666 0l204.8 204.8z m0 324.266667a42.666667 42.666667 0 0 1 0 60.330667l-204.8 204.8a42.666667 42.666667 0 0 1-60.330666 0l-204.8-204.8a42.666667 42.666667 0 0 1 60.330666-60.330667L512 818.56l174.634667-174.592a42.666667 42.666667 0 0 1 56.32-3.541333l4.010666 3.541333z"
      fill="currentColor"
      p-id="5063"
    ></path>
  </svg>
);
