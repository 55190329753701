import { Fragment } from "react";
import { Tooltip } from "antd";
import LeftBottomIcons from "@/icons/LeftBottomIcons";

function VipButton() {
  return (
    <Fragment>
      <Tooltip
        title="成为会员"
        placement="right"
      >
        <a href="/console/vip">
          <div className="leftMenuBottom-Btn vip-button">
            <LeftBottomIcons type="vip" />
          </div>
        </a>
      </Tooltip>
    </Fragment>
  );
}

export default VipButton;
