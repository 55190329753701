import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { enableComposer, disableComposer } from "@/utils/index";
import { MESSAGE_TYPES } from "@/constants/message";
import NormalMessage from "./NormalMessage";
import { useMatch, useLocation } from "react-router-dom";
import { isObject } from "lodash-es";
import { message } from "antd";

function ResolveMessage({ messageId, setComposerText }) {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [finished, setFinished] = useState(false);
  const [createdAt, setCreatedAt] = useState();
  const [answerType, setAnswerType] = useState(0);
  const [status, setStatus] = useState(0);
  const [messageConfig, setMessageConfig] = useState(null);
  const [questionType, setQuestionType] = useState();
  const { quickReply } = useSelector((state) => state.chat);
  const { tokenInfo } = useSelector((state) => state.user);
  const includedQuestionTypes = ["论文撰写", "文献综述", "开题报告", "文章重组", "文章优化", "长文撰写"];
  const matchAtDocument = useMatch("/document");

  const {
    messageId: currentMessageId,
    messageValue,
    messageTyping,
    messageEndTime,
    messageType,
    messageText,
    processingText,
    messageReText,
    messageErr,
  } = useSelector((state) => state.eventsource.messageMap[messageId] || {});

  useEffect(() => {
    disableComposer();
    return () => {
      enableComposer();
    };
  }, []);

  useEffect(() => {
    if (!finished && currentMessageId === messageId) {
      if (messageErr) {
        setStatus(0);
        setFinished(true);
        if (quickReply !== "语音转文字") {
          enableComposer();
        }
        return;
      }
      if (messageEndTime) {
        pathname === "/chat" && dispatch.chat.version();
        pathname === "/document" && dispatch.document.version();
        // if ([2, 3, 4, 5].includes(messageValue.status)) {
        //   setStatus(messageValue.status);
        //   setCreatedAt(messageValue.created_at);
        //   setAnswerType(messageValue.answer_type);
        //   setQuestionType(messageValue.question_type);
        //   setMessageConfig(messageValue.config);
        //   setFinished(true);
        //   enableComposer();
        //   [0, -1].includes(user_type_number) && dispatch.user.getTokenInfo();
        // } else disableComposer();
        if (quickReply === "语音转文字") {
          disableComposer();
        }
        return;
      }
      if (messageType === "delta" || messageType === "reasoning" || messageType === "processing") {
        setStatus(0);
      }
    }
  }, [
    finished,
    currentMessageId,
    messageTyping,
    messageValue,
    messageType,
    messageText,
    messageErr,
    messageEndTime,
    processingText,
  ]);

  if (messageText || processingText || messageReText) {
    return (
      <NormalMessage
        messageType={
          (includedQuestionTypes.includes(quickReply) && status === 5) || (matchAtDocument && status === 5)
            ? "outline"
            : quickReply === "生成PPT" && status === 5 && answerType === 31
              ? "ppt"
              : MESSAGE_TYPES[answerType]
        }
        questionType={questionType}
        text={messageText && isObject(messageText) ? messageText : messageText.slice(0, messageTyping)}
        createdAt={createdAt}
        setComposerText={setComposerText}
        messageId={messageId}
        position={"left"}
        isLast={true}
        finished={finished}
        placeholderType={quickReply}
        status={status}
        config={messageConfig}
        processingText={processingText}
        messageReText={messageReText}
      />
    );
  } else
    return (
      <NormalMessage
        messageType={"message-typing"}
        position={"left"}
      />
    );
}

export default ResolveMessage;
