import React, { useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ThingIcons from "@/icons/ThingIcons";
import styles from "./index.module.less";

const ChatPluginAction = ({ quickReply, tutors, setText, messageList, setMessageList }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pluginPlaceHolder } = useSelector((state) => state.chat);

  const onClickNew = useCallback(() => {
    const cacheQuickReply = quickReply;
    const cachePlaceHolder = pluginPlaceHolder;
    setText("");
    if (quickReply) {
      dispatch.chat.resetSession();
      dispatch.chat.update({
        quickReply: cacheQuickReply,
        replyVersion: Date.now(),
        placeHolderText: cachePlaceHolder,
        chatAtHome: false,
        windowTitle: "新会话",
      });
    } else {
      dispatch.chat.resetSession();
      dispatch.chat.update({
        chatAtHome: false,
        windowTitle: "新会话",
      });
    }
    setMessageList([]);
    navigate("/chat", { replace: true });
  }, [quickReply, pluginPlaceHolder, setText, setMessageList, messageList]);

  const onClickTutor = () => {
    const url = tutors[quickReply];
    if (url) {
      window.open(url, "blank");
    }
  };

  return (
    <div className={styles.ChatPluginAction}>
      <div className="actionHeader">
        <div
          className="newSessionBtn"
          onClick={onClickNew}
        >
          <ThingIcons type="newChat" />
          <span className="name">开启新会话</span>
        </div>
      </div>
      {/* <div className="actionFooter">
        <span onClick={onClickTutor}>使用教程</span>
      </div> */}
    </div>
  );
};

export default React.memo(ChatPluginAction);
