import React, { useRef, useEffect, useState, useCallback } from "react";
import styles from "./index.module.less";
import { useSelector, useDispatch } from "react-redux";
import { useRequest, useHover } from "ahooks";
import { getWindowSize, getFormattedDate } from "@/utils/helper.js";
import { data as dataService } from "@/services";
import { feedback } from "@/utils/index";
import { useNavigate } from "react-router-dom";
import Scrollbars from "react-custom-scrollbars-2";
import { Spin, Empty } from "antd";
import emptyImg from "@/assets/empty.png";
import classNames from "classnames";

const ChatPluginContent = ({ quickReply = "普通对话", curSessionId }) => {
  const navigate = useNavigate();
  const isInit = useRef(true);
  const containerRef = useRef(null);
  const currentRef = useRef(null);
  const currentScrollTop = useRef(0);
  const isHover = useHover(containerRef);
  const { tokenInfo } = useSelector((state) => state.user);
  const { jwt } = useSelector((state) => state.token);
  const { chatPlaceholderList } = useSelector((state) => state.app);
  const { windowTitle, version } = useSelector((state) => state.chat);
  const { height: screenHeight } = getWindowSize();
  const dispatch = useDispatch();
  const scrollbarRef = useRef(null);
  const [offsetTop, setOffsetTop] = useState(0);
  const [requesting, setRequesting] = useState(true);
  const [page, setPage] = useState(1);
  const [pageSize] = useState(Math.max(10 * Math.ceil((Math.max(screenHeight, 1080) - 100 - 62) / 10 / 50), 20));
  const [total, setTotal] = useState(0);
  const [loadedList, setLoadedList] = useState([]);

  const onScrollFrame = ({ clientHeight, scrollTop, scrollHeight }) => {
    setOffsetTop(scrollTop);
    if (scrollTop > 0 && scrollTop !== currentScrollTop.current) {
      currentScrollTop.current = scrollTop;
    }
    if (clientHeight + Math.floor(scrollTop) > scrollHeight - 5 && !requesting) {
      setPage(page + 1);
    }
  };

  useEffect(() => {
    if (!windowTitle || (page === 1 && requesting)) {
      return;
    }
    // 如果已经是第一页，则直接搜索
    if (page === 1 && !requesting && !isInit.current) {
      isInit.current = false;
      setRequesting(true);
      getSessionListByPage();
      return;
    }
    // 否则触发从第一页开始加载
    setPage(1);
  }, [jwt, version]);

  useEffect(() => {
    if (!windowTitle || (page === 1 && requesting)) {
      return;
    }

    if ((page - 1) * pageSize <= total) {
      isInit.current = false;
      jwt && setRequesting(true);
      getSessionListByPage();
    } else {
      showMessage();
    }
  }, [page]);

  const showMessage = () => {
    const ptarget = document.querySelector(".chat-plugin-history-scrollbar");
    const element = ptarget?.querySelector("div.chat-plugin-history-list-wrapper");
    element.classList.add("scrolling-bottom");
  };

  useEffect(() => {
    getSessionListByPage();
  }, [quickReply]);

  const { run: getSessionListByPage } = useRequest(
    () =>
      dataService.getSessionByPage({
        scenes: quickReply,
        page_size: pageSize,
        page,
        type: 0, //查询对话类型，不查询文献类型
        ordering: tokenInfo?.session_order, // 排序
      }),
    {
      manual: true,
      ready: !!jwt && windowTitle,
      cacheKey: "cacheKey-history",
      cacheTime: -1,
      refreshDeps: [version, history, quickReply],
      debounceWait: 500,
      onSuccess: (res) => {
        setRequesting(false);
        if (res.status === "OK") {
          setTotal(res.data?.count || 0);
          setLoadedList(page === 1 ? [...res.data.results] : [...loadedList, ...res.data.results]);
          if (scrollbarRef?.current) {
            setTimeout(() => {
              scrollbarRef.current.scrollTop(currentScrollTop.current || 0);
              // if (currentRef.current) {
              //   currentRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
              // }
            });
          }
        }
      },
      onError: (err) => {
        setRequesting(false);
        feedback(err.data);
      },
      onFinally: () => {
        setRequesting(false);
      },
    }
  );

  const onClick = (item) => {
    const { id, title } = item;
    if (id === curSessionId) {
      return;
    }
    navigate(`/chat?id=${id}`, { replace: true });
    dispatch.document.resetSession();
    dispatch.chat.update({
      sessionId: id,
      windowTitle: title,
      replyVersion: Date.now(),
      quickReply: quickReply,
      placeHolderText: chatPlaceholderList[quickReply],
      chatAtHome: false,
    });
    dispatch.user.update({ checking: false });
  };

  return (
    <div
      className={styles.ChatPluginHistory}
      ref={containerRef}
    >
      <div className={classNames("ChatPluginHistoryContent", { fixed: isHover && quickReply !== "普通对话" })}>
        <p className="historyTitle">历史会话</p>
        <div className={classNames("historyList")}>
          <Scrollbars
            ref={scrollbarRef}
            autoHide
            autoHideTimeout={1000}
            autoHideDuration={200}
            style={{ height: "100%" }}
            onScrollFrame={onScrollFrame}
            className="chat-plugin-history-scrollbar"
          >
            {!requesting && !loadedList?.length ? (
              <Empty
                description={"暂无历史会话~"}
                image={emptyImg}
              />
            ) : null}
            {loadedList?.length ? (
              <div className={classNames("historyListContent", "chat-plugin-history-list-wrapper")}>
                {loadedList.map((item, idx) => {
                  return (
                    <div
                      ref={(node) => (item.id === curSessionId ? (currentRef.current = node) : null)}
                      className={classNames("historyListItem", {
                        current: item.id === curSessionId,
                      })}
                      key={idx}
                      onClick={() => onClick(item)}
                    >
                      <span>{item.title}</span>
                      <span>
                        {getFormattedDate(
                          tokenInfo?.session_order.includes("modified") ? item.modified_at : item.created_at
                        )?.value || "-"}
                      </span>
                    </div>
                  );
                })}
              </div>
            ) : null}
            {requesting ? (
              <Spin
                tip="加载中..."
                style={{ display: "block", marginTop: "0px" }}
              />
            ) : null}
          </Scrollbars>
        </div>
      </div>
    </div>
  );
};

export default React.memo(ChatPluginContent);
