export function isIOS() {
  const userAgent = navigator.userAgent.toLowerCase();
  return /iphone|ipad|ipod/.test(userAgent);
}

export const getUserDesktopRatio = () => {
  let ratio = 0;
  const screen = window.screen;
  const ua = navigator.userAgent.toLowerCase();

  if (window.devicePixelRatio !== undefined) {
    ratio = window.devicePixelRatio;
  } else if (~ua.indexOf("msie")) {
    if (screen.deviceXDPI && screen.logicalXDPI) {
      ratio = screen.deviceXDPI / screen.logicalXDPI;
    }
  } else if (window.outerWidth !== undefined && window.innerWidth !== undefined) {
    ratio = window.outerWidth / window.innerWidth;
  }

  if (ratio) {
    ratio = Math.round(ratio * 100);
  }
  return ratio;
};

export const getWindowSize = () => {
  const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
  return {
    width,
    height,
  };
};

export const getFormattedDate = (modified_at) => {
  const todayStart = new Date();
  todayStart.setHours(0, 0, 0, 0); // 设置为今日开始时间

  const weekStart = new Date();
  weekStart.setHours(0, 0, 0, 0); // 设置为本周开始时间
  weekStart.setDate(weekStart.getDate() - 7);

  const monthStart = new Date();
  monthStart.setHours(0, 0, 0, 0); // 设置为30天前开始时间
  monthStart.setDate(monthStart.getDate() - 30);
  const datetime = new Date(modified_at);
  const time = [
    `${datetime.getHours()}`.replace(/^(\d)$/, "0$1"),
    `${datetime.getMinutes()}`.replace(/^(\d)$/, "0$1"),
  ].join(":");
  const date_md = [
    `${datetime.getMonth() + 1}`.replace(/^(\d)$/, "0$1"),
    `${datetime.getDate()}`.replace(/^(\d)$/, "0$1"),
  ].join("-");
  const date_ymd = [datetime.getFullYear(), date_md].join("-");
  if (datetime > todayStart) {
    return {
      type: "today",
      value: time,
    };
  }
  if (datetime > weekStart) {
    return {
      type: "week",
      value: date_md,
    };
  }
  if (datetime > monthStart) {
    return {
      type: "month",
      value: date_md,
    };
  }
  return {
    type: "earlier",
    value: date_ymd,
  };
};
