import React, { useRef, useCallback, useState } from "react";
import { useRequest, useHover } from "ahooks";
import { message, Typography, Input, Image, Modal, Tooltip, Checkbox } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import useScreenSize from "@/hooks/useScreenSize";
import { feedback } from "@/utils/index";
import { data as dataService } from "@/services/data";
import { has, last } from "lodash-es";
import FileColorIcons from "@/icons/FileColorIcons";
import ToolbarIcons from "@/icons/ToolbarIcons";
import PluginCardIcons, { isIconExist } from "@/icons/PluginCardIcons/index";
import { useHistoryPreview } from "../historyPreview.hook";
import styles from "./index.module.less";

const typeList = ["DOC", "DOCX", "PDF", "PPT", "PPTX", "XLS", "XLSX", "TXT", "CSV"];

function HistoryItem({
  detail,
  onDelete,
  isFocusItem,
  setFocusItem,
  isBatchDel,
  batchDeleteList,
  updateBatchSelected,
}) {
  const ref = useRef(null);
  const isHover = useHover(ref, {
    onEnter: () => {
      setFocusItem(detail.id);
    },
    onLeave: () => {
      setFocusItem(null);
    },
  });
  const { middle } = useScreenSize();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { onPreview } = useHistoryPreview();
  const [isEdit, setIsEdit] = useState(false);
  const [editName, setEditName] = useState("");
  const { id, type, scenes, title, paper, modified_at_formatted, media_list = [] } = detail;
  const { paper_info } = paper || {};
  const { ext: fileExtension } = paper_info || {};
  const { sessionId: chatSessionId } = useSelector((state) => state.chat);
  const { sessionId: documentSessionId } = useSelector((state) => state.document);
  const { previewItem } = useSelector((state) => state.history);

  const onClick = useCallback(() => {
    if (!middle) {
      onPreview(detail);
      return;
    }
    if (detail.id === previewItem?.id) {
      dispatch.history.closePreview({});
      return;
    }
    dispatch.history.update({
      showPreview: true,
      previewItem: detail,
    });
  }, [detail, previewItem]);

  const onRename = useCallback(
    (e, isEdit) => {
      e.stopPropagation();
      if (isEdit) {
        setEditName(detail.title);
      } else {
        setEditName("");
      }
      setIsEdit(isEdit);
    },
    [detail]
  );

  const onSave = useCallback((e) => {
    e.stopPropagation();
    saveHandler();
  });

  const { run: saveHandler } = useRequest(
    () =>
      dataService.updateSession(detail.id, {
        title: editName,
      }),
    {
      ready: !!editName,
      manual: true,
      onSuccess: (res) => {
        message.success("标题保存成功！");
        detail.title = editName;
      },
      onError: (err) => {
        feedback(err.data);
      },
      onFinally: () => {
        setIsEdit(false);
      },
    }
  );

  const onRemove = useCallback(
    async (e) => {
      e.stopPropagation();
      Modal.confirm({
        title: "删除",
        icon: <ExclamationCircleOutlined />,
        content: `确认删除会话【${title}】吗？`,
        okText: "确认",
        cancelText: "取消",
        onOk: async () => {
          try {
            const res = await dataService.bulkRemoveSession({ ids: [id] });
            if (res.status === "OK") {
              message.success("操作成功");
              // 删除已打开的会话
              if (chatSessionId === id) {
                dispatch.chat.resetSession();
                navigate("/chat", { replace: true });
              } else if (documentSessionId === id) {
                dispatch.document.resetSession();
                navigate("/paper", { replace: true });
              }
            }
            onDelete(detail);
          } catch (err) {
            feedback(err.data);
          }
        },
      });
    },
    [detail]
  );

  const onExport = useCallback(
    (e) => {
      try {
        e.stopPropagation();
        let src = `https://chat.phitrellis.com/api/session/${id}/download_docx/`;
        window.open(src, "_blank");
      } catch (err) {
        feedback(err.data || "内容导出失败！");
      }
      // try {
      //   e.stopPropagation();
      //   const date = Date.now();
      //   let iframe = document.createElement("iframe");
      //   iframe.src = `https://chat.phitrellis.com/api/session/${id}/download_docx/`;
      //   iframe.style.display = "none";
      //   iframe.id = `iframe-${date}`;
      //   document.body.appendChild(iframe);
      //   setTimeout(() => {
      //     document.body.removeChild(iframe);
      //   }, 1000);
      // } catch (err) {
      //   feedback(err.data || "内容导出失败！");
      // }
    },
    [id]
  );

  const onClickPrevent = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  return detail.deletedStatus ? null : (
    <div
      ref={ref}
      onClick={(e) => {
        if (e.target.tagName === "INPUT") return;
        onClick();
      }}
      className={classNames(styles.historyItem, "history-item", { active: previewItem?.id === id })}
    >
      <div className={classNames("historyItemHeader", media_list?.length ? "with-content" : "")}>
        <div className={classNames("historyItemHeaderLeft", { isEdit })}>
          {isBatchDel && (
            <div className="historyCheckbox">
              <Checkbox
                onChange={(e) => {
                  e.stopPropagation();
                  updateBatchSelected(
                    batchDeleteList.includes(id) ? batchDeleteList.filter((x) => x !== id) : [...batchDeleteList, id]
                  );
                }}
              />
            </div>
          )}
          <span className="historyItemIcon">
            {type === 0 ? (
              isIconExist({ type: scenes }) ? (
                <PluginCardIcons type={scenes} />
              ) : (
                <PluginCardIcons type="默认消息" />
              )
            ) : null}
            {type === 1 ? <FileColorIcons type={(fileExtension || "").toUpperCase()} /> : null}
            {type === 2 ? <FileColorIcons type="Folder" /> : null}
          </span>
          <span className="historyItemType">{type === 0 ? scenes || "普通对话" : scenes || "文献对话"}</span>
          <span className="historyItemDivider"></span>
          <span className="historyItemTitle">
            {!isEdit ? (
              title
            ) : (
              <div
                className="historyItemTitleEdit"
                onClick={(e) => e.stopPropagation()}
              >
                <Input
                  value={editName}
                  onChange={(e) => setEditName(e.target.value)}
                  onKeyDown={(e) => {
                    if (e && e.keyCode && e.keyCode === 13) {
                      onSave(e);
                    }
                  }}
                />

                <div className="historyItemOpt">
                  <span onClick={(e) => onRename(e, false)}>
                    <ToolbarIcons type="cancel" />
                  </span>
                  <span onClick={onSave}>
                    <ToolbarIcons type="confirm" />
                  </span>
                </div>
              </div>
            )}
          </span>
        </div>

        <div className="historyItemHeaderRight">
          {isEdit ? null : isFocusItem && isHover ? (
            <div className="historyItemOpt">
              <Tooltip title="重命名">
                <span onClick={(e) => onRename(e, true)}>
                  <ToolbarIcons type="renameIcon" />
                </span>
              </Tooltip>
              <Tooltip title="删除">
                <span onClick={onRemove}>
                  <ToolbarIcons type="trashIcon" />
                </span>
              </Tooltip>
              <Tooltip title="导出word">
                <span
                  className="exportBtn"
                  onClick={(e) => onExport(e)}
                >
                  <ToolbarIcons type="exportIcon" />
                </span>
              </Tooltip>
            </div>
          ) : (
            <span className="historyItemTime">{modified_at_formatted}</span>
          )}
        </div>
      </div>
      {media_list.length ? (
        <div className="historyItemMedia">
          {media_list.slice(0, 3).map((item) => {
            if (item.type === "image") {
              return (
                <div
                  className="historyItemImg"
                  key={item.url}
                  onClick={onClickPrevent}
                >
                  <Image
                    height="80px"
                    src={item.url}
                  />
                </div>
              );
            }
            if (item.type === "video") {
              return (
                <div
                  className="historyItemImg"
                  key={item.url}
                  onClick={onClickPrevent}
                >
                  <video
                    src={item.url}
                    autoPlay={false}
                    controls={true}
                  />
                </div>
              );
            }
            const fileType = has(item, "url")
              ? last(item.url?.split(".")).toUpperCase()
              : last(item.name.split(".")).toUpperCase();
            return (
              <div
                className="historyItemAttach"
                key={item.url || item.name}
              >
                <div className="historyItemFile">
                  {typeList.includes(fileType) ? <FileColorIcons type={fileType} /> : <FileColorIcons type="NORMAL" />}
                  <Typography.Text
                    className="file-name"
                    ellipsis
                  >
                    {item.name}
                  </Typography.Text>
                </div>
              </div>
            );
          })}
          {media_list.length > 3 ? (
            <span className="historyItemMore">+{Math.max(media_list.length - 3, 1)}</span>
          ) : null}
        </div>
      ) : null}
    </div>
  );
}

export default React.memo(HistoryItem);
