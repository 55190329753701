import React, { useCallback } from "react";
import styles from "./index.module.less";
import ScrollToIcons from "@/icons/ScrollToIcons";

const ScrollToTop = React.forwardRef((props, ref) => {
  const toTopHandler = useCallback(() => {
    const pele = document.querySelector(".message-scrollbar");
    const ele = pele?.querySelector("div:first-child");
    ele.scrollTo({ top: 0, behavior: "smooth" });
  });

  return (
    <div
      ref={ref}
      className={styles.ScrollToTop}
      onClick={toTopHandler}
    >
      <div className="scroll-to-top-content">
        <ScrollToIcons type="up" />
      </div>
    </div>
  );
});

ScrollToTop.displayName = "ScrollToTop";

export default React.memo(ScrollToTop);
