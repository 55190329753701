import { v4 as uuidv4 } from "uuid";
import { urlToBase64Async } from "@/utils/tool";

export const token = {
  state: {
    visitorId: undefined,
    language: "Zh",
    model: "DEEPSEEK",
    hadLogin: false, // 是否登录(扫码登录、手机邮箱登录，临时登录为true)
    hadOpenId: false, // 是否绑定openId
    split: 68,
    jwt: "",
    avatar: "",
  },
  reducers: {
    update(state, payload) {
      return {
        ...state,
        ...payload,
      };
    },
    init(state, payload) {
      // const id = uuidv4();
      return {
        ...state,
        visitorId: undefined,
        language: "Zh",
        model: "DEEPSEEK",
        hadLogin: false,
        hadOpenId: false,
        avatar: "",
        jwt: "",
        ...payload,
      };
    },
  },
  effects: (dispatch) => ({
    async updateAvatar(url) {
      const avatar64 = await urlToBase64Async(url);
      dispatch.token.update({ avatar: avatar64 });
    },
  }),
};
