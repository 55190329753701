class ChatStreamEventHandler {
  constructor(store) {
    this._store = store;
    this._loopMap = new Map();
  }

  startMessageTyping(messageId) {
    // 如果没有内容，则忽略
    if (!this._store.getState().eventsource.messageMap[messageId]) {
      return;
    }

    // 定时器执行内容
    const loop = () => {
      const {
        messageTyping = 0,
        messageValue,
        messageText,
        messageEndTime,
      } = this._store.getState().eventsource.messageMap[messageId] || {};
      let { timeId } = this._loopMap.get(messageId) || {};

      // 如果typing结束，则记录messageEndTime，用于判断完成
      if (messageValue && messageValue.answer && messageTyping && messageTyping >= messageValue.answer.length) {
        this._store.dispatch.eventsource.updateMessage({
          ...this._store.getState().eventsource.messageMap[messageId],
          messageEndTime: messageEndTime || Date.now(),
        });
        timeId && window.clearTimeout(timeId);
        return;
      }
      if (!messageText) {
        return;
      }
      timeId = setTimeout(async () => {
        let { timeId } = this._loopMap.get(messageId) || {};
        // 每次随机打印1~2个字符
        let step = [1, 2][Math.random() > 0.5 ? 1 : 0];
        // 如果总字数很长（通常是论文已经写完，所有章节都返回），比如超过1000，则按照每次总长度0.5%数量打印
        if (messageText && messageText.length > 1000) {
          step = Math.max(Math.round(messageText.length * 0.005), step);
        }
        const { messageTyping: curMessageTyping } = this._store.getState().eventsource.messageMap[messageId] || {};
        await this._store.dispatch.eventsource.updateMessage({
          ...this._store.getState().eventsource.messageMap[messageId],
          messageTyping: messageText ? Math.min(messageText.length, curMessageTyping + step) : curMessageTyping + step,
        });
        loop();
      }, 35);
      this._loopMap.set(messageId, { timeId });
    };
    loop();
  }

  onMessage(message) {
    const { id, type, value } = message;
    let {
      messageId,
      messageBuffer = "",
      messageStartTime,
      messageEndTime,
      messageTyping = 0,
      messageArray = [],
      messageReText = "",
      messageReBuffer = "",
    } = this._store.getState().eventsource.messageMap[id] || {};

    if (type === "end") {
      // 如果像  画图 等输出图片的 或者 出现错误处理status为3，不需要打字机效果，直接end的情况
      if (
        !messageStartTime &&
        value &&
        value.answer &&
        (Number(value.answer_type) !== 0 || Number(value.status) === 3)
      ) {
        this._store.dispatch.eventsource.updateMessage({
          messageId: id,
          messageType: type,
          messageText: value.answer,
          messageBuffer: "",
          messageReBuffer: "",
          messageValue: value,
          messageErr: null,
          messageTyping: value.answer.length,
          messageStartTime: messageStartTime,
          messageEndTime: Date.now(),
        });
        return;
      }

      // 否则
      this._store.dispatch.eventsource.updateMessage({
        messageId: id,
        messageType: type,
        messageText: value.answer,
        messageBuffer: "",
        messageReBuffer: "",
        messageValue: value,
        messageErr: null,
        messageTyping: messageTyping,
        messageStartTime: messageStartTime,
        messageEndTime: messageEndTime,
        processingText: messageArray,
        messageReText,
      });
      // 否则是需要打字机效果，但是之前没有一个delta第一次就返回end，则需要先触发typing效果
      if (!messageStartTime) {
        this.startMessageTyping(id);
      }
      return;
    }

    // 将delta传回来的内容，记录到messageBuffer上

    let lastText = "";
    let startTime = null;
    if (type === "delta" && (!messageId || (!!messageId && id === messageId))) {
      lastText = (messageBuffer || "") + value;
      startTime = messageStartTime || Date.now();
    }

    if (type === "processing" && (!messageId || (!!messageId && id === messageId))) {
      messageArray = [...messageArray, value];
    }

    let allReasonText;
    if (type === "reasoning" && (!messageId || (!!messageId && id === messageId))) {
      allReasonText = (messageReBuffer || "") + value;
    }

    if (type === "delta" || type === "processing" || type === "reasoning") {
      this._store.dispatch.eventsource.updateMessage({
        messageId: id,
        messageType: type,
        messageText: lastText,
        messageBuffer: lastText,
        messageValue: null,
        messageErr: null,
        messageTyping: messageTyping || 0,
        messageStartTime: startTime,
        messageEndTime: messageEndTime,
        processingText: messageArray,
        messageArray,
        messageReText: allReasonText || messageReText,
        messageReBuffer: allReasonText,
      });
    }

    // 如果是第一个delta，则开始触发typing效果
    if (!messageStartTime) {
      this.startMessageTyping(id);
    }
  }
}

export default ChatStreamEventHandler;
