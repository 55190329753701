import React, { useCallback } from "react";
import styles from "./index.module.less";
import ScrollToIcons from "@/icons/ScrollToIcons";

const ScrollToBottom = React.forwardRef((props, ref) => {
  const toBottomHandler = useCallback(() => {
    const pele = document.querySelector(".message-scrollbar");
    const ele = pele?.querySelector("div:first-child");
    ele.scrollTo({ behavior: "smooth", top: ele.scrollHeight });
  });

  return (
    <div
      ref={ref}
      className={styles.ScrollToBottom}
      onClick={toBottomHandler}
    >
      <div className="scroll-to-bottom-content">
        <ScrollToIcons type="down" />
      </div>
    </div>
  );
});

ScrollToBottom.displayName = "ScrollToBottom";

export default React.memo(ScrollToBottom);
