import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export const useHistoryPreview = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { chatPlaceholderList } = useSelector((state) => state.app);

  const onPreview = (detail) => {
    const { id, type, scenes, title, paper, pdf_path, paths = [] } = detail;
    const { paper_info } = paper || {};

    dispatch.history.update({
      active: false,
      showPreview: false,
      previewItem: null,
    });
    // 如果是插件对话
    if (type === 0) {
      navigate(`/chat?id=${id}`, { replace: true });
      dispatch.document.resetSession();
      dispatch.chat.update({
        sessionId: id,
        windowTitle: title,
        replyVersion: Date.now(),
        quickReply: scenes,
        placeHolderText: chatPlaceholderList[scenes],
        chatAtHome: false,
      });
      dispatch.user.update({ checking: false });
      return;
    }
    // 如果是文献目录对话
    if (type === 2 && paths) {
      dispatch.chat.resetSession();
      dispatch.document.update({
        paperInfo: null,
        paths: [...paths, paper.id],
        paperNavPaths: [...(paper.path || [])],
        file: null,
        sessionType: 2,
        windowTitle: title,
        version: Date.now(),
        paperId: paper.id,
        currentChatType: 2,
        subPaper: false,
        folderName: paper?.title || title,
        parent: paper?.id,
      });
      navigate(`/document?id=${id}`, { replace: true });
      return;
    }
    // 如果是文献单篇对话
    if (type === 1) {
      dispatch.chat.resetSession();
      dispatch.document.update({
        paths: [...paths, paper.id],
        file: pdf_path || title || null,
        sessionType: 1,
        paperInfo: paper_info,
        currentChatType: 1,
        subPaper: false,
        folderName: paper.path?.[0]?.title || "",
      });
      navigate(`/document?id=${id}`, { replace: true });
      dispatch.document.update({ paperInfo: paper_info, paperNavPaths: [...(paper.path || [])] });
    }
  };

  return {
    onPreview,
  };
};
