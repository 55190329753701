import { useCallback, useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useRequest, useTrackedEffect } from "ahooks";
import { disableComposer, enableComposer, feedback, clearChatScrollBottom } from "@/utils/index";
import { Alert, Button, Space, message, Tooltip, Avatar } from "antd";
import { QUESTION_ERROR } from "@/constants/writing";
import { MODEL_ICONS } from "@/constants/model";
import { data as dataService } from "@/services/data";
import { flatten, isEmpty, isObject, includes, flattenDeep, findIndex } from "lodash-es";
import { MESSAGE_TYPES } from "@/constants/message";
import { v4 as uuidv4 } from "uuid";
import useScreenSize from "@/hooks/useScreenSize";
import Marquee from "react-fast-marquee";
import ChatSider from "../ChatSider";
import styles from "./index.module.less";
import ChatInput from "@/components/common/ChatUI/ChatInput";
import ChatBody from "@/components/common/ChatUI/ChatBody";
import { newConfigKey } from "./key";

function ChatWindow({ prompts, tutors }) {
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const { online, sessionId, version, replyVersion, quickReply, placeHolderText, CNKI, Wanfang, Wiley } = useSelector(
    (state) => state.chat
  );
  const { checking, checkedList } = useSelector((state) => state.user);
  const { model, avatar = "/user.png" } = useSelector((state) => state.token);
  const config = useSelector((state) => state.config);
  const [messageList, setMessageList] = useState([]);
  const [showSider, setShowSider] = useState(true);
  const [chatPlaceholder, setChatPlaceholder] = useState("");
  const { middle } = useScreenSize();

  // 初始化
  useEffect(() => {
    if (sessionId) {
      if (isEmpty(messageList))
        setMessageList([
          {
            _id: `${Date.now()}-loading`,
            id: `${Date.now()}-loading`,
            position: "right",
            content: {
              message_type: "message-loading",
            },
          },
        ]);
    }
    // if (quickReply && !messageList?.length) {
    //   // 插件视频简介
    //   setMessageList([
    //     {
    //       _id: `${Date.now()}-answer`,
    //       id: `${Date.now()}-answer`,
    //       position: "right",
    //       content: {
    //         message_type: "plugin-placeholder",
    //         placeholder_type: quickReply,
    //       },
    //     },
    //   ]);
    // }
  }, [replyVersion]);

  useEffect(() => {
    dispatch.user.resetCheck();
  }, [sessionId]);

  useEffect(() => {
    const info = middle
      ? quickReply
        ? placeHolderText || "请输入提问内容，Shift+Enter换行"
        : "请输入提问内容，Shift+Enter换行，支持网络链接(图片、网页、PDF论文等)，支持拖拽和粘贴"
      : "请输入提问内容";
    setChatPlaceholder(info);
  }, [middle, quickReply, placeHolderText, sessionId]);

  // 获取session的messageList
  const { loading } = useRequest(() => dataService.getSession(sessionId), {
    ready: !!sessionId,
    refreshDeps: [sessionId, version, model, avatar],
    onSuccess: (res) => {
      if (res.status === "OK") {
        const { messages: msgs, scenes } = res.data;
        const results = msgs.map((msg, index) => {
          let messageType, _question, config;
          _question = msg.question;
          config = msg.config;
          messageType = msg.config === null ? "text" : "file";
          const rtn = [
            {
              _id: `${msg.id}-question`,
              id: `${msg.id}-question`,
              type: "normal-message",
              content: {
                createdAt: msg.created_at,
                text: _question,
                config: config,
                messageId: msg.id,
                message_type: messageType,
                isLast: index === msgs.length - 1,
                question_type: msg.question_type,
              },
              user: {
                avatar: avatar,
              },
              position: "right",
            },
          ];
          if (index < msgs.length - 1 || msg.answers[0].answer) {
            rtn.push({
              _id: `${msg.id}-answer`,
              id: `${msg.id}-answer`,
              type: "answers-message",
              content: {
                createdAt: msg.modified_at,
                messageId: msg.id,
                answers: msg.answers,
                isLast: index === msgs.length - 1,
                model: msg.model,
                question_type: msg.question_type,
                question_text: msg.question,
              },
              user: { avatar: MODEL_ICONS[msg.model] },
              position: "left",
            });
          } else {
            rtn.push({
              _id: `${msg.id}-answer`,
              id: `${msg.id}-answer`,
              type: "resolve-message",
              content: {
                createdAt: msg.modified_at,
                messageId: msg.answers[0].id,
                status: msg.status,
                config: config,
                message_type: MESSAGE_TYPES[msg.answer_type],
                isLast: index === msgs.length - 1,
                question_type: msg.question_type,
                model: msg.model,
              },
              user: { avatar: MODEL_ICONS[msg.model] },
              position: "left",
            });
          }
          return rtn;
        });
        setMessageList([...flatten(results)]);
        dispatch.chat.update({
          windowTitle: res.data?.title,
          replyVersion: Date.now(),
          quickReply: scenes,
          chatAtHome: false,
        });
      } else {
        feedback(res.data);
      }
    },
    onError: (err) => {
      if (err.code === 404) {
        setMessageList([]);
      } else {
        feedback(err.data);
      }
    },
  });

  // 删除
  const deleteHandler = useCallback(async () => {
    try {
      await dataService.removeAnswers({ ids: checkedList });
      message.success("删除成功！");
      dispatch.user.resetCheck();
      dispatch.chat.version();
    } catch (err) {
      feedback(err.data);
    }
  }, [checkedList]);

  // 处理消息发送，信息添加到到messageList
  const appendMsg = (msg) => {
    setMessageList((prev) => [...prev, msg]);
  };

  // 发送消息
  const sendHandler = useCallback(
    async (type, val) => {
      let newKey = newConfigKey(quickReply); //config参数不同插件，key值不同
      if (!sessionId) setMessageList([]);
      if (type === "text" && val.trim()) {
        appendMsg({
          _id: uuidv4(),
          id: uuidv4(),
          type: "normal-message",
          content: { text: val, message_type: "text" },
          user: { avatar: avatar },
          position: "right",
          hasTime: false,
        });
        disableComposer();
        try {
          const _sessionId = sessionId || uuidv4();
          const _config = config;
          if (newKey !== null) {
            for (let key in newKey) {
              newKey[key] = _config[key];
            }
          } else {
            newKey = online ? { online: true } : null;
          }
          const params = {
            question: val,
            session: _sessionId,
            question_type: quickReply,
            model,
            config: newKey,
          };
          const res = await dataService.ask(params);
          appendMsg({
            _id: uuidv4(),
            id: uuidv4(),
            type: "resolve-message",
            content: {
              messageId: res.data?.answer_id,
              status: res.data?.status,
            },
            user: { avatar: MODEL_ICONS[model] },
            position: "left",
            hasTime: false,
          });
          if (!sessionId) {
            dispatch.chat.update({
              sessionId: _sessionId,
              latestSessionId: _sessionId,
            });
          }
        } catch (err) {
          let errorType = isObject(err.data) ? flattenDeep(Object.values(err.data))[0] : err.data;
          let errorIndex = findIndex(
            Object.keys(QUESTION_ERROR).map((err) => includes(errorType, err)),
            (err) => err
          );
          // 402展示接口返回message, meassageType为no-times, 响应TimesErrorBubble
          let messageType = err.code === 402 ? "no-times" : Object.values(QUESTION_ERROR)[errorIndex];
          if (err.code === 402) {
            dispatch.user.getTokenInfo();
          }
          if (messageType === "just-notice") {
            feedback(errorType);
          } else
            appendMsg({
              _id: uuidv4(),
              id: uuidv4(),
              type: "normal-message",
              content: {
                text: err.code === 402 ? err.data : errorType, //返回402，text为err.data
                message_type: messageType,
                model,
                status: err.code === 403 || err.code === 402 ? 3 : null, //返回403, 402，status为3标红显示
              },
              user: { avatar: MODEL_ICONS[model] },
              position: "left",
              hasTime: false,
            });
          enableComposer();
        }
      } else if (type === "file") {
        const { text, fileList } = val;
        appendMsg({
          _id: uuidv4(),
          id: uuidv4(),
          type: "normal-message",
          content: {
            // text: { content: text, link: fileList },
            text: text,
            message_type: "file",
            config: {
              link: fileList,
            },
          },
          user: { avatar: avatar },
          position: "right",
          hasTime: false,
        });
        disableComposer();
        try {
          const _sessionId = sessionId || uuidv4();
          const linkList = fileList.map((file) => {
            return {
              url: file.response.data,
              name: file.name,
              isImage: file.isImage,
            };
          });
          const _config = config;
          if (newKey !== null) {
            for (let key in newKey) {
              newKey[key] = _config[key];
            }
          }
          const res = await dataService.ask({
            question: text,
            config: {
              link: linkList,
              ...newKey,
            },
            session: _sessionId,
            question_type: quickReply,
            model,
          });
          appendMsg({
            _id: uuidv4(),
            id: uuidv4(),
            type: "resolve-message",
            content: {
              messageId: res.data?.id,
              status: res.data?.status,
            },
            user: { avatar: MODEL_ICONS[model] },
            position: "left",
            hasTime: false,
          });
          if (!sessionId) {
            dispatch.chat.update({
              sessionId: _sessionId,
              latestSessionId: _sessionId,
            });
          }
          dispatch.chat.version();
        } catch (err) {
          let errorType = isObject(err.data) ? flattenDeep(Object.values(err.data))[0] : err.data;
          let errorIndex = findIndex(
            Object.keys(QUESTION_ERROR).map((err) => includes(errorType, err)),
            (err) => err
          );
          if (err.code === 402) {
            dispatch.user.getTokenInfo();
          }
          let messageType = Object.values(QUESTION_ERROR)[errorIndex];
          if (messageType === "just-notice") {
            feedback(errorType);
          } else
            appendMsg({
              _id: uuidv4(),
              id: uuidv4(),
              type: "normal-message",
              content: {
                model,
                text: errorType,
                message_type: messageType,
                status: err.code === 403 ? 3 : null, //返回403，status为3标红显示
              },
              user: { avatar: MODEL_ICONS[model] },
              position: "left",
              hasTime: false,
            });
          enableComposer();
        }
      }
    },
    [sessionId, dispatch, model, quickReply, online, config]
  );

  // 文献下载
  useTrackedEffect(
    (changes) => {
      changes?.forEach((changeIndex) => {
        const { url, title, downloaded } = [CNKI, Wanfang, Wiley][changeIndex];
        if (!downloaded) return;
        const scholarName = ["知网", "万方", "Wiley"][changeIndex];

        const question = `下载${scholarName}文献：${title}`;
        appendMsg({
          _id: uuidv4(),
          id: uuidv4(),
          type: "normal-message",
          content: { text: question, message_type: "text" },
          user: { avatar: avatar },
          position: "right",
          hasTime: false,
        });
        disableComposer();
        const send = async () => {
          try {
            const res = await dataService.ask({
              question: `下载${scholarName}文献：${title}`,
              config: {
                url,
                name: title,
              },
              session: sessionId,
              question_type: `${scholarName}下载`,
              model,
            });
            appendMsg({
              _id: uuidv4(),
              id: uuidv4(),
              type: "resolve-message",
              content: {
                messageId: res.data?.id,
                status: res.data?.status,
              },
              user: { avatar: MODEL_ICONS[model] },
              position: "left",
              hasTime: false,
            });
            !sessionId && dispatch.chat.updateLatestSessionId(sessionId);
            dispatch.chat.version();
          } catch (err) {
            let errorType = isObject(err.data) ? flattenDeep(Object.values(err.data))[0] : err.data;
            let errorIndex = findIndex(
              Object.keys(QUESTION_ERROR).map((err) => includes(errorType, err)),
              (err) => err
            );
            let messageType = Object.values(QUESTION_ERROR)[errorIndex];
            if (messageType === "just-notice") {
              feedback(errorType);
            } else
              appendMsg({
                _id: uuidv4(),
                id: uuidv4(),
                type: "normal-message",
                content: {
                  text: errorType,
                  message_type: messageType,
                  model,
                },
                user: { avatar: MODEL_ICONS[model] },
                position: "left",
                hasTime: false,
              });
            enableComposer();
          }
        };
        send();
        const ipt = document.querySelector(".Composer-input");
        ipt.focus();
        dispatch.chat.updateWiley({ downloaded: false });
      });
    },
    [CNKI, Wanfang, Wiley]
  );

  return (
    <div className={styles.chatWindow}>
      {checking && (
        <Alert
          type="info"
          banner
          message={
            <Marquee
              pauseOnHover
              gradient={false}
            >
              点击 删除 按钮即可删除选中的问答，点击 取消 按钮退出批量删除。
            </Marquee>
          }
          closeIcon={<></>}
          action={
            <Space>
              <Button
                size="small"
                onClick={() => {
                  dispatch.user.resetCheck();
                }}
              >
                取消
              </Button>
              <Button
                size="small"
                disabled={isEmpty(checkedList)}
                danger
                type="primary"
                onClick={deleteHandler}
              >
                删除
              </Button>
            </Space>
          }
          closable
        />
      )}
      <div className={styles.chatWindowMain}>
        <ChatBody
          messageList={messageList}
          setMessageList={setMessageList}
          setText={inputRef.current?.setText}
          onOutlineSend={inputRef.current?.onOutlineSend}
          onRefineSend={inputRef.current?.onRefineSend}
        />
        <ChatInput
          ref={inputRef}
          placeholder={chatPlaceholder}
          onSend={sendHandler}
          appendMsg={appendMsg}
          messageList={messageList}
        />
      </div>
      {middle && (
        <ChatSider
          tutors={tutors}
          prompts={prompts}
          showSider={showSider}
          setShowSider={setShowSider}
          setText={inputRef.current?.setText}
          messageList={messageList}
          setMessageList={setMessageList}
          curSessionId={sessionId}
        />
      )}
    </div>
  );
}

export default ChatWindow;
