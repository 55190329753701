import { useCallback, useState, useEffect } from "react";
import { Button, message, Tooltip } from "antd";
import { useSelector } from "react-redux";
import { useMatch } from "react-router-dom";
import useScreenSize from "@/hooks/useScreenSize";
import ToolsIcons from "@/icons/ToolsIcons";
import CustomTip from "@/components/common/CustomTip";
import QuickRepliesIcons from "@/icons/QuickRepliesIcons";

function OutlineButton({ quickReply, onOutlineSend, initialValue, setText, appendMsg, messageList }) {
  const { middle } = useScreenSize();
  const [show, setShow] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const { avatar = "/user.png" } = useSelector((state) => state.token);
  const { editOutlineData } = useSelector((state) => state.chat);
  const matchAtChat = useMatch("/chat");

  // 按钮点击添加可编辑框在messageList中
  const outlineHandler = useCallback(() => {
    setTooltipOpen(false);
    // 新消息删除plugin-placeholder消息展示
    // if (matchAtChat && messageList.length === 1) {
    //   messageList.pop();
    // }
    const isExist = messageList.some((item) => item._id === "edit-outline");
    if (isExist) {
      message.error("当前会话已有编辑框");
      return;
    }
    // 添加可编辑框
    appendMsg({
      _id: "edit-outline",
      id: `${Date.now()}-answer`,
      type: "normal-message",
      content: {
        message_type: "outline",
        showButtons: true, // 编辑框功能按钮区域
      },
      position: "right",
      user: {
        avatar: avatar,
      },
    });
  }, [appendMsg, matchAtChat, messageList, avatar]);

  useEffect(() => {
    if (editOutlineData) {
      setShow(true);
      setText(editOutlineData);
    }
  }, [editOutlineData]);

  const component = (
    <CustomTip content={["创建需要撰写的文章大纲", "非必须，可以直接在下方输入框输入题目，系统会自动生成大纲"]}>
      <Button
        size="small"
        className="input-tools-btn"
        onClick={outlineHandler}
        onMouseEnter={() => setTooltipOpen(true)}
        onMouseLeave={() => setTooltipOpen(false)}
      >
        <div className="icon-bg">
          <QuickRepliesIcons type="提取大纲" />
        </div>
        大纲编辑
      </Button>
    </CustomTip>
  );

  return component;

  // if (matchAtDocument && sessionType === 2 && !["普通问答", "逐一问答"].includes(quickReplyMode)) {
  //   return component;
  // }

  // if (matchAtChat) {
  //   switch (quickReply) {
  //     case "文献综述":
  //     case "论文撰写":
  //     case "开题报告":
  //     case "长文撰写":
  //     case "文章重组":
  //     case "文章优化":
  //     case "文章配图":
  //       return component;
  //   }
  // }
}

export default OutlineButton;
